import CASE_ACCOUNT_CONTACT_MAPPINGS from '../../config/accountContact.fields.mapping.json'

export class AccountContact {

    constructor(accountContactData) {
        const inputAccountContactData = accountContactData || {};

        Object.keys(CASE_ACCOUNT_CONTACT_MAPPINGS).forEach(fieldName => {
            if (inputAccountContactData.hasOwnProperty(CASE_ACCOUNT_CONTACT_MAPPINGS[fieldName])) {
                const val = inputAccountContactData[CASE_ACCOUNT_CONTACT_MAPPINGS[fieldName]];

                if (val == 'null') {
                    this[fieldName] = null;
                } else {
                    this[fieldName] = val;
                }
            }
        });
    }
}