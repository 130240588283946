import EventModelBase from "../eventModelBase";

export default class JourneyAdvisorPageLoadedModel extends EventModelBase {

    constructor(assetId, assetType, toolUsed = true, taskCreated = true) {
        super('journeyAdvisorPageLoaded');

        this.page = {
            ...this.page,

        }

        this.attributes = {
            ...this.attributes,

            assetRunAgainst: assetId,
            assetType: assetType,

            toolUsed: toolUsed,
            taskCreated: taskCreated,
        };
    }




}