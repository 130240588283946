import Api from './api/api.js'

// Provided context object should the a vuex store instance
const ACTIONS = {
    NO_OP: function() {},
    CLOSE_MODAL: function() {
        return true;
    },
    BACK_TO_HOME: function() { window.location = '/content/support/my/en-us/account.html/applications'; },
    RESEND_ACTIVATION_EMAIL: async function(a, modalContext, config) {
        const payload = {
            email: config.arg.email
        };

        Cloudera.Modal.loading('Resending activation email ...');

        try {
            await Api.callEndpoint("SKYNET_RESEND_ACTIVATION_EMAIL", { payload });

            let errorModalConfig = Cloudera.Modal.ErrorConfigBuilder.build({ clientErrorCode: "CSP 007" });
            await Cloudera.Modal.error(errorModalConfig).promise;
        } catch (e) {
            let errorModalConfig = Cloudera.Modal.ErrorConfigBuilder.build({ clientErrorCode: e.clientErrorCode });
            await Cloudera.Modal.error(errorModalConfig).promise;
        }
    },
    GO_TO_REGISTRATION: function() { window.location = '/content/support/sso/en-us/register.html' },
    GO_TO_FORGOTTEN_PASSWORD: function() { window.location = '/content/support/sso/en-us/forgotten-password.html' },
    REFRESH_PAGE: function() { document.location.reload(true) },
    RESEND_PASSWORD_RESET_EMAIL: async function(a, modalContext, config) {
        const payload = {
            email: config.arg.email
        };

        Cloudera.Modal.loading('Resending password reset email ...');

        try {
            await Api.callEndpoint("SKYNET_INITIATE_PASSWORD_RESET", { payload });

            let errorModalConfig = Cloudera.Modal.ErrorConfigBuilder.build({ clientErrorCode: "CFP 013" });
            await Cloudera.Modal.error(errorModalConfig).promise;
        } catch (e) {
            let errorModalConfig = Cloudera.Modal.ErrorConfigBuilder.build({ clientErrorCode: e.clientErrorCode });
            await Cloudera.Modal.error(errorModalConfig).promise;
        }
    },
    CONTACT_US_CFP013: function() { window.location.href = "mailto:website-login@cloudera.com?subject=Error: Did not receive password reset email (CFP 013)" },
    CONTACT_US_CSP007: function() { window.location.href = "mailto:website-login@cloudera.com?subject=Error: Did not receive activation email (CSP 007)" },
    CONTACT_US_CSR015: function() { window.location.href = "mailto:website-login@cloudera.com?subject=Error: Did not receive activation email (CSR 015)" },
    CONTACT_US_CSR016: function() { window.location.href = "mailto:website-login@cloudera.com?subject=Error: Did not receive activation email (CSR 016)" },
}

const BUTTON_CONFIGS = {
    default: { title: 'NA', action: ACTIONS.NO_OP },
    CANCEL: { title: 'Cancel', action: 'cancel', loading: false, callback: ACTIONS.CLOSE_MODAL },
    CLOSE_MODAL_WITH_OK_BUTTON: { title: 'OK', action: 'close', loading: false, callback: ACTIONS.CLOSE_MODAL },
    BACK_TO_HOME_WITH_CLOSE_BUTTON: { title: 'Close', action: 'close', loading: false, callback: ACTIONS.BACK_TO_HOME },
    BACK_TO_HOME: { title: 'Back To Home', action: 'close', loading: false, callback: ACTIONS.BACK_TO_HOME },
    RESEND_ACTIVATION_EMAIL: { title: 'Resend Activation', action: 'close', loading: false, callback: ACTIONS.RESEND_ACTIVATION_EMAIL },
    GO_TO_REGISTRATION: { title: 'Register For An Account', action: 'close', loading: false, callback: ACTIONS.GO_TO_REGISTRATION },
    GO_TO_FORGOTTEN_PASSWORD: { title: 'Initiate password reset', action: 'close', loading: false, callback: ACTIONS.GO_TO_FORGOTTEN_PASSWORD },
    REFRESH_CURRENT_PAGE: { title: 'Refresh The Page', action: 'close', loading: false, callback: ACTIONS.REFRESH_PAGE },
    RESEND_PASSWORD_RESET_EMAIL: { title: 'Resend Password Reset', action: 'close', loading: false, callback: ACTIONS.RESEND_PASSWORD_RESET_EMAIL },
    CONTACT_US_NO_PASSWORD_RESET_EMAIL_CFP013: { title: "Contact Us", action: 'close', loading: false, callback: ACTIONS.CONTACT_US_CFP013 },
    CONTACT_US_NO_ACTIVATION_EMAIL_CSP007: { title: "Contact Us", action: 'close', loading: false, callback: ACTIONS.CONTACT_US_CSP007 },
    CONTACT_US_NO_ACTIVATION_EMAIL_CSR015: { title: "Contact Us", action: 'close', loading: false, callback: ACTIONS.CONTACT_US_CSR015 },
    CONTACT_US_NO_ACTIVATION_EMAIL_CSR016: { title: "Contact Us", action: 'close', loading: false, callback: ACTIONS.CONTACT_US_CSR016 },
}




/////////////////////////////////////////////
// EXPORTS
/////////////////////////////////////////////
export default {
    buttons: BUTTON_CONFIGS,
}