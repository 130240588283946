import EventModelBase from "../eventModelBase";

export default class UnsuccessfulSearchEventModel extends EventModelBase {

    constructor(searchTerm) {
        super('unsuccessfulSearch');

        this.page = {
            ...this.page,
        }

        this.attributes = {
            ...this.attributes,
        };

        this.search = {
            searchTerm: searchTerm,
        };
    }




}