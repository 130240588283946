import MDF_ACCRUAL_DATA_FIELD_MAPPINGS from '../../config/mdfAccrualData.fields.mapping.json';

import moment from 'moment-timezone';

export class MdfAccrualData {

    constructor(mdfAccrualData) {
        const inputMdfAccrualData = mdfAccrualData || {};

        Object.keys(MDF_ACCRUAL_DATA_FIELD_MAPPINGS).forEach(fieldName => {
            if (inputMdfAccrualData.hasOwnProperty(MDF_ACCRUAL_DATA_FIELD_MAPPINGS[fieldName])) {
                const val = inputMdfAccrualData[MDF_ACCRUAL_DATA_FIELD_MAPPINGS[fieldName]];

                if (val == 'null' || val == null) {
                    this[fieldName] = '-';
                } else {
                    this[fieldName] = val;
                }
            }
        });

        if (this.ExpirationDate) {
            this.ExpirationDateFormatted = moment(this.ExpirationDate).format('YYYY-MM-DD');
        }

        let fiscalQuarters = {
            '02': 'Q1',
            '05': 'Q2',
            '08': 'Q3',
            '11': 'Q4'
        };

        var fromDate = moment(this.AccruedFromDate);
        var month = fromDate.format('MM');
        var year = fromDate.format('YY');
        var quarter = fiscalQuarters[month];
        this.Quarter = quarter + ' FY' + year;
    }
}