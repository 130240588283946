import dateValidator from './dateValidator'
import dateRangeValidator from './dateRangeValidator'
import defaultValidator from './defaultValidator'
import requiredValidator from './requiredValidator'
import passwordValidator from './passwordValidator'
import emailValidator from './emailValidator'
import minlengthValidator from './minlengthValidator'
import maxlengthValidator from './maxlengthValidator'
import noSymbolValidator from './noSymbolValidator'
import onlyNameSymbolValidator from './onlyNameSymbolValidator';
import checkNicknameRegexpValidator from './checkNicknameRegexpValidator'
import onlyCitySymbolsValidator from './onlyCitySymbolsValidator'
import onlyPhoneSymbolsValidator from './onlyPhoneSymbolsValidator'
import noNumberValidator from './noNumberValidator'
import passwordEqualityValidator from './passwordEqualityValidator'
import cannotBeTheOldPasswordValidator from './cannotBeTheOldPasswordValidator'
import securityQuestionAnswerValidator from './securityQuestionAnswerValidator'
import stateRequiredValidator from './stateRequiredValidator'
import checkNicknameInSkynetValidator from './checkNicknameInSkynetValidator'
import notClouderianEmailValidator from './notClouderianEmailValidator'
import skynetEmailValidator from './skynetEmailValidator'
import onlyNumberValidator from './onlyNumberValidator'
import onlyCompanySymbolValidator from './onlyCompanySymbolValidator'
import skynetMigratedUserValidator from './skynetMigratedUserValidator'
import noDefaultNamingValidator from './noDefaultNamingValidator'
import skynetContactIdEmailCheckerValidator from './skynetContactIdEmailCheckerValidator'






export default {
    'dateValidator': dateValidator,
    'dateRangeValidator': dateRangeValidator,
    'defaultValidator': defaultValidator,
    'requiredValidator': requiredValidator,
    'passwordValidator': passwordValidator,
    'emailValidator': emailValidator,
    'minlengthValidator': minlengthValidator,
    'maxlengthValidator': maxlengthValidator,
    'noSymbolValidator': noSymbolValidator,
    'onlyNameSymbolValidator': onlyNameSymbolValidator,
    'checkNicknameRegexpValidator': checkNicknameRegexpValidator,
    'onlyCitySymbolsValidator': onlyCitySymbolsValidator,
    'onlyPhoneSymbolsValidator': onlyPhoneSymbolsValidator,
    'noNumberValidator': noNumberValidator,
    'passwordEqualityValidator': passwordEqualityValidator,
    'cannotBeTheOldPasswordValidator': cannotBeTheOldPasswordValidator,
    'securityQuestionAnswerValidator': securityQuestionAnswerValidator,
    'stateRequiredValidator': stateRequiredValidator,
    'checkNicknameInSkynetValidator': checkNicknameInSkynetValidator,
    'notClouderianEmailValidator': notClouderianEmailValidator,
    'skynetEmailValidator': skynetEmailValidator,
    'onlyNumberValidator': onlyNumberValidator,
    'onlyCompanySymbolValidator': onlyCompanySymbolValidator,
    'skynetMigratedUserValidator': skynetMigratedUserValidator,
    'noDefaultNamingValidator': noDefaultNamingValidator,
    'skynetContactIdEmailCheckerValidator': skynetContactIdEmailCheckerValidator
    
}