import EventModelBase from "../eventModelBase";
import _ from 'lodash';

export default class RegistrationInitiatedEvent extends EventModelBase {

    constructor(email, application, feature) {
        super('registrationInitiatedEvent');

        this.page = {
            ...this.page,
        }

        this.attributes = {
            ...this.attributes,
            userEnteredEmail: email,
            application: application,
            feature: feature
        };
    }


}