import EXTERNAL_SYS_CONFIGS from '../config/externalSys.json'

export function getCurrentEnv() {
    if ($('head').hasClass('prod')) {
        return 'prod';
    }
    if ($('head').hasClass('stage')) {
        return 'staging';
    }
    return 'dev';
}


export function getDatadogPermission() {
    // if ($('head').hasClass('prod')) {
    //     return 'not-granted';
    // }
    return 'granted';
}

export function isUrlAbsolute(url) {
    var r = new RegExp('^(?:[a-z]+:)?//', 'i');
    return r.test(url);
}

export function isHostedOn3rdPartySystem(domain) {
    return !!getRuntimeEnvironment(domain);
}

export function getRuntimeEnvironment(domain) {
    const envs = Object.keys(EXTERNAL_SYS_CONFIGS.DOMAINS);

    let i = 0;
    while (i < envs.length) {
        const env = envs[i];
        const patterns = EXTERNAL_SYS_CONFIGS.DOMAINS[env];

        let j = 0;
        let hasMatch = false;
        while (j < patterns.length) {
            if (domain.match(patterns[j])) {
                hasMatch = true;
                j = patterns.length;
            } else {
                ++j;
            }
        }

        if (hasMatch) {
            return env;
        }
        ++i;
    }
}

export function getAemUrlForEnvironment(env, secure) {
    const envSelector = (secure) ? "SECURE_ENVS" : "ENVS";
    return EXTERNAL_SYS_CONFIGS.URLS[envSelector][(env || '').toUpperCase()];
}

export function getSkynetUrlForEnvironment(env) {
    return EXTERNAL_SYS_CONFIGS.URLS["SKYNET"][(env || '').toUpperCase()];
}

export function getFullAemUrl(env, aemEndpointPath, secure) {
    if (isUrlAbsolute(aemEndpointPath)) {
        return aemEndpointPath;
    } else {
        return (getAemUrlForEnvironment(env, secure) || '') + aemEndpointPath;
    }
}

export function getFullSkynetUrl(env, skynetEndpointPath) {
    return (getSkynetUrlForEnvironment(env) || '') + skynetEndpointPath;
}

export function isIEBrowser() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) // If Internet Explorer, return version number
    {
        console.log('IE Browser detected');
        return true;
    }
    return false;
}


/////////////////////////////////////////////
// EXPORTS
/////////////////////////////////////////////
export default {
    currentEnv: getCurrentEnv,
    getDatadogPermission,
    isUrlAbsolute,
    getRuntimeEnvironment,
    isHostedOn3rdPartySystem,
    getFullAemUrl,
    isIEBrowser
}