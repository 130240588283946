import EventModelBase from "../eventModelBase";

export default class SuccessfulSearchEventModel extends EventModelBase {

    constructor(searchTerm, numberOfResults) {
        super('successfulSearch');

        this.page = {
            ...this.page,
        }

        this.attributes = {
            ...this.attributes,
        };

        this.search = {
            searchTerm: searchTerm,
            numberOfSearchResults: numberOfResults
        };
    }




}