import _ from 'lodash';
import Utilities from '../../utilities';
import EDL from '../../edl/edl';
import Analytics from '../../analytics';

let ENABLED = false;
let DEPARTMENT = '';
const SKIP_LOAD_EVENTS = 1;
let LOAD_EVENT_COUNT = 0;


export function initialize(location) {
    DEPARTMENT = location.host.substring(0, location.host.indexOf('.'));

    if (!window._satellite) { // || !window._satellite.initialized) {
        // console.warn('Satellite library not found. Can not initialize analytics.');
        return false;
    }

    if (location.host.indexOf('localhost') == -1) {
        ENABLED = true;
    }

}

export function getPageHierarchy(pageName) {
    const hierarchy = {};

    const levels = pageName.substring(pageName.indexOf(':en-us') + ':en-us:'.length).split(':');

    for (let i = 0; i < levels.length; ++i) {
        const element = levels[i];

        if (i == 0) {
            hierarchy.department = element.toUpperCase();
        } else if (i == 1) {
            hierarchy.category = element;
        } else {
            hierarchy['subCategory' + (i - 1)] = element;
        }
    }

    return hierarchy;
}

export function getPageName(pathname) {

    let pathParts = _.filter(pathname.split('/'), p => p.length > 0);

    pathParts.unshift('en-us');
    pathParts.unshift(DEPARTMENT);

    let joinedParts = pathParts.join(':');

    if (joinedParts == 'my:en-us') {
        joinedParts += ':home';
    }

    return joinedParts;
}

export function updatePageDescriptor(location) {
    const pageName = getPageName(location.pathname);
    const hierarchy = getPageHierarchy(pageName);

    const oldPageDesc = Utilities.object.getDeepValue(window.Cloudera, 'Analytics.RequestDescriptor.page') || {};

    const newDescriptor = {
        hierarchy: hierarchy,
        pageName: pageName,
        pageUrl: location.pathname,
        referringUrl: document.referrer,
        lastModified: oldPageDesc.lastModified,
        lastPublished: oldPageDesc.lastPublished
    };

    Utilities.object.setDeepValue(window.Cloudera, 'Analytics.RequestDescriptor.page', newDescriptor);
    /* develblock:start */
    console.log('Analytics Page Descriptor updated');
    console.log(newDescriptor);
    /* develblock:end */
}

function navigatedTo(to, from) {
    // this call is to ensure that the analytics is initialized properly before trying to read values
    Analytics.initialize();

    LOAD_EVENT_COUNT++;

    updatePageDescriptor(window.location);

    EDL.pushEvent(new Cloudera.EDL.models.PageViewEventModel());

    if (ENABLED) {

        if (LOAD_EVENT_COUNT > SKIP_LOAD_EVENTS) {
            console.debug('Firing SPA Page Navigation rule.');
            _satellite.track('DCXA_SPA_PAGELOAD');
        } else {
            console.debug('First SPA Page Navigation is suppressed.');
        }

    } else {
        /* develblock:start */
        console.log('Analytics is DISABLED, so can not track page load.');
        /* develblock:end */
    }
}

export default {
    initialize: initialize,

    navigatedTo: navigatedTo

}