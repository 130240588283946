import { AJAX_ATTEMPT, AJAX_FAILURE, AJAX_SUCCESS } from '../../channels'
import Analytics from '../../analytics'


var ajaxHandler = function(msg, eventInfo) {
    const lifecycle = msg.split('.')[1];
    const { endpointName, endpoint, callOptions, response, uiError, originalError, url } = eventInfo;

    console.log(msg, eventInfo);

    Analytics.events.triggerForEndpoint({
        lifecycle,
        endpointName,
        clientSideErrorCode: (uiError || {}).clientErrorCode,
        skynetErrorCode: (uiError || {}).skynetErrorCode,
        response,
        detailedError: originalError
    });

};

var ajaxAttemptToken = PubSub.subscribe(AJAX_ATTEMPT, ajaxHandler);
var ajaxFailureToken = PubSub.subscribe(AJAX_FAILURE, ajaxHandler);
var ajaxSuccessToken = PubSub.subscribe(AJAX_SUCCESS, ajaxHandler);