import _ from 'lodash'

const lowercaseRegex = /[a-z]/;
const uppercaseRegex = /[A-Z]/;
const numberRegex = /[0-9]/;
const symbolRegex = /[!@#$%^&*_+|~=:;`'"<>?,.\\\/\{\}\[\]\(\)\- ]/;


function validator(val, username) {
    return new Promise((res) => {
        const state = {
            details: {}
        }

        let result = true;

        state.details.lowercase = true;
        if (!lowercaseRegex.test(val)) {
            result = false;
            state.details.lowercase = false;
        }

        state.details.uppercase = true;
        if (!uppercaseRegex.test(val)) {
            result = false;
            state.details.uppercase = false;
        }

        state.details.number = true;
        if (!numberRegex.test(val)) {
            result = false;
            state.details.number = false;
        }

        state.details.symbol = true;
        if (!symbolRegex.test(val)) {
            result = false;
            state.details.symbol = false;
        }

        state.details.username = true;
        const minLength = 3;
        const uppercaseVal = val.toUpperCase();

        const usernameParts = _.sortBy(username.split(/@|\./), 'length');
        const interestingParts = _.filter(usernameParts, part => part.length >= minLength);
        const doesNotContain = _.every(interestingParts, part => { return uppercaseVal.indexOf(part.toUpperCase()) === -1 })

        if (!doesNotContain) {
            result = false;
            state.details.username = false;
        }

        state.details.minlength = true;
        if (val.length < 8) {
            result = false;
            state.details.minlength = false;
        }

        state.details.maxlength = true;
        if (val.length > 128) {
            result = false;
            state.details.maxlength = false;
        }

        // rules: lowercase, uppercase, number (0-9), symbol (!@#$%^&*), can not contain username, min length 8 char
        res({
            result: result,
            state: state
        });
    });
}

function hintParser(text, state) {

    const listAndState =
        '<ul class="tooltip-list no-bullets">' +
        '<li class="' + (state.details.lowercase ? 'valid' : 'invalid') + '">Lowercase letter</li>' +
        '<li class="' + (state.details.uppercase ? 'valid' : 'invalid') + '">Uppercase letter</li>' +
        '<li class="' + (state.details.number ? 'valid' : 'invalid') + '">Number (0-9)</li>' +
        '<li class="' + (state.details.symbol ? 'valid' : 'invalid') + '">Symbol (e.g. !@#$%^&*)</li>' +
        '<li class="' + (state.details.username ? 'valid' : 'invalid') + '">Cannot contain username</li>' +
        '<li class="' + (state.details.minlength ? 'valid' : 'invalid') + '">Be at least 8 characters</li>' +
        '<li class="' + (state.details.maxlength ? 'valid' : 'invalid') + '">Be maximum 128 characters</li>' +
        '</ul>';

    return text.replace('<<<REQUIREMENT_LIST_AND_STATE>>>', listAndState);
}

export default {
    validator: validator,
    validationErrorHint: 'Password must meet the following requirements: <br/><<<REQUIREMENT_LIST_AND_STATE>>>',
    hintParser: hintParser
}