import dateValidator from './dateValidator';

let DEFAULT_YEAR_RANGE = 50;
let INVALID = { result: false, state: { details: {} } };
let VALID = { result: true, state: { details: {} } };

/**
 * Validates if the given date falls into the expected range.
 * @param {String} val the date to validate.
 * @param {String} from Start date.  If not defined: now
 * @param {String} to Ending date. If not defined:'Start+50years' 
 */
function validator(val, from, to) {
    return new Promise(async resolve => {
        let dateRange = await toDateRange(from, to);

        if (!(await dateValidator.validator(val)).result || !dateRange) {
            resolve(INVALID);
        }
        let timeToValidate = new Date(val).getTime();

        if (
            timeToValidate >= dateRange.from.getTime() &&
            timeToValidate <= dateRange.to.getTime()
        ) {
            resolve(VALID);
        }
        resolve(INVALID);
    });
}

async function parseDateString(dateString) {
    if (typeof dateString === 'undefined' || typeof dateString.toLowerCase === 'undefined' || 'now' === dateString.toLowerCase()) {
        return trimHours(new Date(Date.now()));
    }
    let res = await dateValidator.validator(dateString);
    if (res.result) {
        return new Date(dateString);
    }
    console.error('Unable to parse date.');
    return null;
}

function trimHours(date) {
    return new Date(date.getUTCFullYear(), date.getMonth(), date.getDate());
}

export async function toDateRange(from, to) {
    let fromDate;
    let toDate;

    if (!from) {
        // console.warn(
        //     'No date from/to date is defined. Using default: "from": "now", "to": now+50years '
        // );
        fromDate = await parseDateString('now');
    } else {
        fromDate = await parseDateString(from);
    }

    if (!to) {
        // console.warn(
        //     'No date "to" date is defined. Using default: "from" date + 50yrs '
        // );
        toDate = new Date(fromDate.getFullYear() + DEFAULT_YEAR_RANGE, fromDate.getMonth(), fromDate.getDay());
    } else {
        toDate = await parseDateString(to);
    }

    if (!fromDate || !toDate || fromDate.getTime() > toDate.getTime()) {
        // console.error('Invalid date range');
        // console.error(fromDate);
        // console.error(toDate);
        return null;
    }

    return {
        from: fromDate,
        to: toDate
    };
}

export default {
    validator: validator,
    validationErrorMessage: 'Should be between {{0}} and {{1}}'
};