import CASE_ESCALATION_MAPPINGS from '../../config/caseEscalation.fields.mapping.json'

import _ from "lodash";
import moment from 'moment-timezone';

export class CaseEscalation {

    constructor(caseEscalationData) {
        const inputCaseEscalationData = caseEscalationData || {};

        Object.keys(CASE_ESCALATION_MAPPINGS).forEach(fieldName => {
            if (inputCaseEscalationData.hasOwnProperty(CASE_ESCALATION_MAPPINGS[fieldName])) {
                const val = inputCaseEscalationData[CASE_ESCALATION_MAPPINGS[fieldName]];

                if (val == 'null') {
                    this[fieldName] = null;
                } else {
                    this[fieldName] = val;
                }
            }
        });

        if (this.Owner) {
            this.OwnerName = this.Owner.Name;
        }

        if (this.LastModifiedDate) {
            this.LastModifiedDateFormatted = moment(this.LastModifiedDate).tz(moment.tz.guess()).format('MMMM Do, YYYY - hA z');
        }

        if (this.LastModifiedDateFormatted && this.LastModifiedBy) {
            this.LastModifiedDateWithName = this.LastModifiedDateFormatted + " by " + this.LastModifiedBy.Name;
        }

        if (this.CreatedDate) {
            this.CreatedDateFormatted = moment(this.CreatedDate).tz(moment.tz.guess()).format('MMMM Do, YYYY - hA z');
        }

        if (this.CreatedDateFormatted && this.CreatedBy) {
            this.CreatedDateWithName = this.CreatedDateFormatted + " by " + this.CreatedBy.Name;
        }

        if (this.Accepted) {
            this.AcceptedFormatted = moment(this.Accepted).tz(moment.tz.guess()).format('MMMM Do, YYYY - hA z');
        }

        if (this.Closed) {
            this.ClosedFormatted = moment(this.Closed).tz(moment.tz.guess()).format('MMMM Do, YYYY - hA z');
        }
    }

    static convertToSfdcProperties(caseEscalation) {
        const sfdcCaseEscalation = {};

        let clonedDeal = _.cloneDeep(caseEscalation);

        Object.keys(clonedDeal).forEach(uiFieldName => {
            const prop = _.find(CASE_ESCALATION_MAPPINGS, (sfdcPropName, uiPropName) => {
                return uiPropName == uiFieldName;
            });

            sfdcCaseEscalation[prop] = clonedDeal[uiFieldName];
        });

        return sfdcCaseEscalation;
    }
}