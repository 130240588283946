import $ from 'jquery';
import babelcore from 'babel-core/register'

if (!global._babelPolyfill) {
    require('babel-polyfill');
}
// import babelpolyfill from 'babel-polyfill'
import _ from 'lodash';
import axios from 'axios';
// import bootstrap from 'bootstrap-sass'
import urlSearchParamsPolyfill from 'url-search-params-polyfill'
import formdataPolyfill from 'formdata-polyfill'
import tabby from 'tabbyjs'

window.Tabby = tabby;






import TextEncoding from 'text-encoding'

if (!window.TextEncoder) {
    window.TextEncoder = TextEncoding.TextEncoder;
}
if (!window.TextDecoder) {
    window.TextDecoder = TextEncoding.TextDecoder;
}




import Validators from './validation/validators/validators'
import ValidationFramework from './validation/validationFramework'
import Utilities from './utilities.js'
import Configs from './configs.js'
import Auth from './auth.js'
import Ajax from './ajax.js'
import SSO from './sso.js'
import Errors from './errors.js'
import Environment from './environment.js'
import Analytics from './analytics.js'
import Status from './status.js'
import Notifications from './notifications.js'
import Api from './api/api.js'
import Utils from './utils/utils.js'
import Search from './search/search.js'
import HelpfulResourcesSearch from './search/helpfulResourcesSearch.js'
import Datasources from './datasources/datasources.js'
import Adobe from './analytics/index.js'
import Channels from './channels.js';
import PartnerPortal from './partnerPortal.js';
import DataProvider from './dataProvider/dataProvider';
import Etc from './etc';
import EDL from './edl/edl';
import LocalEditing from './localEditing';

import './sass/main';



const reqDesc = Utilities.object.getDeepValue(window, 'Cloudera.Analytics.RequestDescriptor');
const user = Utilities.object.getDeepValue(window, 'Cloudera.User');




Utilities.dom.registerReadyCallback(function() {
    if ($('.dcxa-aem-vertical-nav-bar').length > 0) {
        new Tabby('.dcxa-aem-vertical-nav-bar');
    }

    if ($('.dcxa-aem-tab-bar').length > 0) {
        new Tabby('.dcxa-aem-tab-bar');
    }

});





window.Cloudera = {
    Validators,
    ValidationFramework,
    Utilities,
    Configs,
    Auth,
    Ajax,
    SSO,
    Errors,
    Environment,
    Analytics,
    Status,
    Notifications,
    Api,
    Utils,
    Search,
    HelpfulResourcesSearch,
    Datasources,
    Adobe,
    Channels,
    PartnerPortal,
    DataProvider,
    Etc,
    EDL,
    LocalEditing
};

if (reqDesc) {
    window.Cloudera.Analytics.RequestDescriptor = reqDesc;
    window.Cloudera.isExternal = true;
}
if (user) {
    window.Cloudera.User = user;
    window.Cloudera.isExternal = true;
}



module.exports = {
    Validators,
    ValidationFramework,
    Utilities,
    Configs,
    Auth,
    Ajax,
    SSO,
    Errors,
    Environment,
    Analytics,
    Status,
    Notifications,
    Api,
    Utils,
    Search,
    HelpfulResourcesSearch,
    Datasources,
    Adobe,
    Channels,
    PartnerPortal,
    DataProvider,
    Etc,
    EDL,
    LocalEditing
};


export {
    Validators,
    ValidationFramework,
    Utilities,
    Configs,
    Auth,
    Ajax,
    SSO,
    Errors,
    Environment,
    Analytics,
    Status,
    Notifications,
    Api,
    Utils,
    Search,
    HelpfulResourcesSearch,
    Datasources,
    Adobe,
    Channels,
    PartnerPortal,
    DataProvider,
    Etc,
    EDL,
    LocalEditing
};