import EventModelBase from "../eventModelBase";
import _ from 'lodash';

export default class RegistrationCompletedEvent extends EventModelBase {

    constructor(application, feature) {
        super('registrationCompletedEvent');

        this.page = {
            ...this.page,
        }

        this.attributes = {
            ...this.attributes,
            application: application,
            feature: feature
        };
    }


}