import Api from '../api/api';
import Utilities from '../utilities';


const TYPES = {
    AJAX: 'ajax',
    MANUAL: 'manual'
}

export class DatasourceManager {
    constructor() {
        this.registeredDatasources = {};
        this.datasourceUpdateCallbacks = {};
    }


    getDatasourceCount() {
        return Object.keys(this.registeredDatasources).length;
    }


    registerImpl(sourceId, datasourceConfig, type) {
        if (this.registeredDatasources[sourceId]) {
            throw 'Datasource already registered (' + sourceId + '). Please use a different id.';
        }

        if (!datasourceConfig || typeof datasourceConfig !== 'object') {
            throw 'Datasource (' + sourceId + ') can not be created without a config object.';
        }

        const newSource = {
            sourceType: type,
            config: datasourceConfig,
            updateCallbacks: [],
            errorCallbacks: [],
            retrievedData: []
        };

        if (datasourceConfig.initialData) {
            newSource.retrievedData.push(datasourceConfig.initialData);
        }

        this.registeredDatasources[sourceId] = newSource;
    }

    registerDatasource(sourceId, datasourceConfig) {
        this.registerImpl(sourceId, datasourceConfig, TYPES.AJAX);
    }

    registerManualDatasource(sourceId, datasourceConfig) {
        this.registerImpl(sourceId, datasourceConfig, TYPES.MANUAL);
    }

    registerListener(sourceId, updateCallback, errorCallback) {
        if (!this.registeredDatasources[sourceId]) {
            throw 'Can not register a listener for a non-existent datasource (' + sourceId + ').';
        }
        if (typeof updateCallback != 'function') {
            throw 'updateCallback parameter has to be a function';
        }
        if (errorCallback && typeof errorCallback != 'function') {
            throw 'updateCallback parameter has to be a function if provided';
        }
        this.registeredDatasources[sourceId].updateCallbacks.push(updateCallback);

        if (errorCallback) {
            this.registeredDatasources[sourceId].errorCallbacks.push(errorCallback);
        }

        const fetchedDataArray = this.registeredDatasources[sourceId].retrievedData;
        if (fetchedDataArray.length > 0) {
            updateCallback(fetchedDataArray[fetchedDataArray.length - 1]);
        }
    }


    callUpdateCallbacks(sourceId, result) {
        this.registeredDatasources[sourceId].updateCallbacks.forEach(updateCb => {
            updateCb(result);
        });
    }

    callErrorCallbacks(sourceId, error) {
        this.registeredDatasources[sourceId].errorCallbacks.forEach(errorCb => {
            errorCb(error);
        });
    }

    resultExtractor(response, path) {
        if (!path || path.length == 0) {
            return response;
        }

        if (!Utilities.object.hasDeepValue(response, path)) {
            console.warn('Response has no property at ' + path);
        }

        return Utilities.object.getDeepValue(response, path);
    }

    async updateDatasource(sourceId, data) {
        if (!this.registeredDatasources[sourceId]) {
            throw 'Datasource does not exists (' + sourceId + ')';
        }
        const source = this.registeredDatasources[sourceId];


        if (source.sourceType == TYPES.MANUAL) {
            if (!data) {
                throw 'Datasource is set to manual, therefore you need to provide the data to update the datasource.'
            }
            const result = this.resultExtractor(data, source.config.path);
            this.registeredDatasources[sourceId].retrievedData.push(result);
            this.callUpdateCallbacks(sourceId, result);
            return;
        }

        if (source.sourceType == TYPES.AJAX) {
            if (source.config.verb === 'GET') {
                try {
                    const ajaxResponse = await Api.Ajax.get(source.config.url);
                    const result = this.resultExtractor(ajaxResponse, source.config.path);
                    this.registeredDatasources[sourceId].retrievedData.push(result);
                    this.callUpdateCallbacks(sourceId, result);
                } catch (e) {
                    // TODO: normalize AJAX error handling
                    this.callErrorCallbacks(sourceId, e);
                }
                return;
            }
            if (source.config.verb === 'POST') {
                try {
                    const ajaxResponse = await Api.Ajax.post(source.config.url, source.config.payload);
                    const result = this.resultExtractor(ajaxResponse, source.config.path);
                    this.registeredDatasources[sourceId].retrievedData.push(result);
                    this.callUpdateCallbacks(sourceId, result);
                } catch (e) {
                    // TODO: normalize AJAX error handling
                    this.callErrorCallbacks(sourceId, e);
                }
                return;
            }

            throw 'HTTP verb (' + source.config.verb + ') not supported';
        }

        throw 'SourceType (' + source.sourceType + ') not supported';

    }


}



export default new DatasourceManager();