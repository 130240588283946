const symbolRegex = /^[\w-_]*$/;

function validator(val) {
    return new Promise((res) => {

        const state = {
            details: {}
        }

        const result = symbolRegex.test(val);
        state.details.noSymbol = result;

        res({
            result: result,
            state: state
        });
    });
}

export default {
    validator: validator,
    validationErrorMessage: 'Invalid nickname syntax.',
    validationErrorHint: 'Nickname does not match the expected format: can contain only letters, numbers, _, -.'
}