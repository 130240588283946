import EventModelBase from "../eventModelBase";

export default class JourneyAdvisorAssetViewRedirectionModel extends EventModelBase {

    constructor(assetId) {
        super('journeyAdvisorAssetViewRedirection');

        this.page = {
            ...this.page,
        }

        this.attributes = {
            ...this.attributes,

            assetId: assetId
        };
    }




}