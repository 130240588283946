import Utilities from '../../utilities';

export const SERVER_SIDE_ANALYTICS_DATA = JSON.parse($('meta[name=cloudera-analytics]').attr('content') || "{}");

export default class EventModelBase {

    constructor(eventId) {
        this.event = eventId;

        this.page = {
            pageUrl: document.URL,
            previousPageUrl: ''
        };

        this.attributes = {
            sfdcContactId: Utilities.object.getDeepValue(SERVER_SIDE_ANALYTICS_DATA, 'actor.contactId'),
            sfdcAccountId: Utilities.object.getDeepValue(SERVER_SIDE_ANALYTICS_DATA, 'actor.accountId'),
            khorosSsoId: Utilities.object.getDeepValue(SERVER_SIDE_ANALYTICS_DATA, 'actor.lithiumSsoId'),
            oktaId: Utilities.object.getDeepValue(SERVER_SIDE_ANALYTICS_DATA, 'actor.oktaId'),
            auroraId: '',

            application: '',
            feature: '',
        }
    }


    generatePageName() {
        const subdomain = (window.location.hostname.split('.')[0] || '').toLowerCase();
        const title = (document.title || '').toLowerCase();

        let path = 'en-us/' + (window.location.pathname.substring(1) || '').toLowerCase().split('.html')[0];
        if (path.endsWith('/')) {
            path = path.substring(0, path.length - 1);
        }

        const pageName = subdomain + ':' + path.split('/').join(':') + ':' + title.split(' ').join('-');

        return pageName;
    }

}