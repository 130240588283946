import Utilities from './utilities'
import { updatePageDescriptor } from './analytics/spa/api'

function publishAnalyticsData(data) {
    Utilities.object.setDeepValue(Cloudera, 'Analytics.RequestDescriptor', data);
}

function collectClientSideAnalyticsData() {
    return {};
}


function isAnalyticsReady() {
    return typeof _satellite !== 'undefined' && typeof Cloudera.Analytics.execute !== 'undefined';
}


function triggerAnalyticsEventForEndpointByLifecycle({ lifecycle, endpointName, clientSideErrorCode, skynetErrorCode, response, detailedError }) {
    if (!isAnalyticsReady()) {
        // console.warn('Should report analytics, but analytics framework is not initialized...');
    } else {
        try {
            Cloudera.Analytics.execute(lifecycle, endpointName, clientSideErrorCode, skynetErrorCode, response, detailedError);
        } catch (error) {
            console.log("Error at analytics: ");
            console.log(error);
        }
    }
}

const SKYNET_HANDLER_PARAMS = {
    LIFECYCLE: 0,
    ENDPOINT_NAME: 1,
    CLIENT_ERROR: 2,
    SKYNET_ERROR: 3,
    RESPONSE: 4,
    DETAILED_ERROR: 5
}

const GENERIC_LIFECYCLE_HANDLERS = {
    'failure': function() {
        Utilities.object.setDeepValue(Cloudera.Analytics, 'status.ClientError', getSkynetHandlerParam(arguments, SKYNET_HANDLER_PARAMS.CLIENT_ERROR));
        Utilities.object.setDeepValue(Cloudera.Analytics, 'status.SkyNetError', getSkynetHandlerParam(arguments, SKYNET_HANDLER_PARAMS.SKYNET_ERROR));
    }
}

function getSkynetHandlerParam(args, i) {
    args = args || [];
    return args[i];
}

function execute(lifecycle, endpointName, clientSideErrorCode, skynetErrorCode, response, detailedError) {
    console.log("Current Endpoint: " + endpointName);
    console.log("Current Lifecycle: " + lifecycle);

    const currentRule = Utilities.object.getDeepValue(Cloudera, 'Analytics.RuleConfigs.' + endpointName) || {};


    const genericLifecycleHandler = Utilities.object.getDeepValue(Cloudera, 'Analytics.GenericLifecycleHandler.' + lifecycle);
    if (typeof genericLifecycleHandler === "function") {
        genericLifecycleHandler.apply(null, arguments);
    }

    const currentLifecycleConfig = currentRule[lifecycle];
    if (currentLifecycleConfig) {

        const dtmRule = Utilities.object.getDeepValue(currentLifecycleConfig, 'dtmRule');
        const prepareFuncName = Utilities.object.getDeepValue(currentLifecycleConfig, 'prepareFunc');
        const prepareFunc = Utilities.object.getDeepValue(window, prepareFuncName);

        console.log("Current DTM Rule: " + dtmRule);
        console.log("Current prepare function: " + prepareFuncName);

        let cancelDtmCall = false;

        if (typeof prepareFunc === "function") {
            const prepResult = prepareFunc.apply(null, arguments);
            if (Utilities.object.getDeepValue(prepResult, 'cancelDtmCall')) {
                cancelDtmCall = true;
            }
        }

        if (cancelDtmCall) {
            console.log("Calling '" + dtmRule + "' cancelled by the custom prepare function.");
            return;
        }

        if (!dtmRule || dtmRule.length < 1) {
            console.warn("No valid DTM rule specified for the current endpoint-lifecycle pair. Please check DTM config.");
            return;
        }
        _satellite.track(dtmRule);
    }
}

let initialized = false;

function initialize() {
    if (!initialized) {
        if (!window.Cloudera.isExternal) {
            // collect descriptor is on AEM
            const serverSideAnalyticsData = JSON.parse($('meta[name=cloudera-analytics]').attr('content') || "{}");
            const clientSideAnalyticsData = collectClientSideAnalyticsData();

            publishAnalyticsData({...serverSideAnalyticsData, ...clientSideAnalyticsData });
        } else {
            // check if page is UNKNOWN or not and try to fix if it is
            updatePageDescriptor(window.location);
        }
        initialized = true;
    }
}


if (Utilities && Utilities.dom) {
    Utilities.dom.registerReadyCallback(function() {
        initialize();
    });
}



/////////////////////////////////////////////
// EXPORTS
/////////////////////////////////////////////
export default {
    execute: execute,
    events: {
        triggerForEndpoint: triggerAnalyticsEventForEndpointByLifecycle
    },
    GenericLifecycleHandler: GENERIC_LIFECYCLE_HANDLERS,
    CONSTS: {
        SKYNET_HANDLER_PARAMS: SKYNET_HANDLER_PARAMS
    },
    Helpers: {
        getSkynetHandlerParam: getSkynetHandlerParam
    },
    initialize
}