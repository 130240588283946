import EventModelBase from "../eventModelBase";

export default class JourneyAdvisorAdviceLoadingErrorModel extends EventModelBase {

    constructor(assetId, assetType, skynetError, clientError) {
        super('journeyAdvisorAdviceLoadingError');

        this.page = {
            ...this.page,

        }

        this.attributes = {
            ...this.attributes,

            assetRunAgainst: assetId,
            assetType: assetType,

            skynetErrorCode: skynetError,
            clientSideErrorCode: clientError
        };
    }




}