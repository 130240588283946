import EventModelBase from "../eventModelBase";
import _ from 'lodash';

export default class SearchFilterEventModel extends EventModelBase {

    constructor(searchFacets) {
        super('searchFilter');

        this.page = {
            ...this.page,
        }

        this.attributes = {
            ...this.attributes,
        };


        const facetString = _.map(searchFacets, (f) => { return 'content_facets_string: \"' + f + '\"' }).join(' AND ');

        this.search = {
            searchFacetFilter: facetString
        };
    }




}