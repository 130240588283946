import EventModelBase from "../eventModelBase";
import _ from 'lodash';

export default class RegistrationErrorEvent extends EventModelBase {

    constructor(email, application, feature, skynetErrorCode, clientSideErrorCode='') {
        super('registrationErrorEvent');

        this.page = {
            ...this.page,
        }

        this.attributes = {
            ...this.attributes,
            userEnteredEmail: email,
            skynetErrorCode: skynetErrorCode,
            clientSideErrorCode: clientSideErrorCode,
            application: application,
            feature: feature
        };
    }


}