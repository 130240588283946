import UPGRADE_ADVISOR_VALIDATION_FIELD_MAPPINGS from '../../config/cdpUpgradeAdvisorValidation.fields.mapping.json';
import UPGRADE_ADVISOR_VALIDATION_OVERALL_RECOMMENDATION_MAPPINGS from '../../config/cdpUpgradeAdvisorValidationOverallRecommendation.mapping.json'

export class UpgradeAdvisorValidation {
    constructor(upgradeAdvisorValidationData) {
        const inputUpgradeAdvisorValidationData = upgradeAdvisorValidationData || {};

        Object.keys(UPGRADE_ADVISOR_VALIDATION_FIELD_MAPPINGS).forEach(fieldName => {
            if (inputUpgradeAdvisorValidationData.hasOwnProperty(UPGRADE_ADVISOR_VALIDATION_FIELD_MAPPINGS[fieldName])) {
                const val = inputUpgradeAdvisorValidationData[UPGRADE_ADVISOR_VALIDATION_FIELD_MAPPINGS[fieldName]];

                if (val == 'null' || val == null) {
                    this[fieldName] = '-';
                } else {
                    this[fieldName] = val;
                }
            }
        });

        if (UPGRADE_ADVISOR_VALIDATION_OVERALL_RECOMMENDATION_MAPPINGS.hasOwnProperty(this.OverallRecommendation)) {
            this.OverallRecommendationLabel = UPGRADE_ADVISOR_VALIDATION_OVERALL_RECOMMENDATION_MAPPINGS[this.OverallRecommendation];
        }
    }
}