import Utilities from '../utilities'

import PageViewEventModel from './models/pageView';
import DownloadInitiated from './models/downloadInitiated';
import DownloadSuccessful from './models/downloadSuccessful';

import JourneyAdvisorPageLoaded from './models/journeyAdvisory/journeyAdvisorPageLoaded';
import JourneyAdvisorAdviceLoadingError from './models/journeyAdvisory/journeyAdvisorAdviceLoadingError';
import JourneyAdvisorAccountDetailsLoadingError from './models/journeyAdvisory/journeyAdvisorAccountDetailsLoadingError';
import JourneyAdvisorAssetLoadingError from './models/journeyAdvisory/journeyAdvisorAssetLoadingError';
import JourneyAdvisorAssetViewRedirection from './models/journeyAdvisory/journeyAdvisorAssetViewRedirection';

import SearchRequested from './models/search/searchRequested';
import SearchFilter from './models/search/searchFilter';
import SearchResultClicked from './models/search/searchResultClicked';
import SuccessfulSearch from './models/search/successfulSearch';
import UnsuccessfulSearch from './models/search/unsuccessfulSearch';


import HelpfulResourcesUpdate from './models/cases/helpfulResourcesUpdate';
import HelpfulResourceClicked from './models/cases/helpfulResourceClicked';
import CaseSubmitted from './models/cases/caseSubmitted';
import CaseSubmissionError from './models/cases/caseSubmissionError';
import NotEntitledForCases from './models/cases/notEntitledForCases';
import NewCaseInteraction from './models/cases/newCaseInteraction';


import IsMigratedUserEvent from './models/sso/isMigratedUser';
import LoginInitiatedEvent from './models/sso/loginInitiated';
import LoginErrorEvent from './models/sso/LoginError';
import LoginSuccessEvent from './models/sso/loginSuccess';
import RegistrationCompletedEvent from './models/sso/registrationCompleted';
import RegistrationErrorEvent from './models/sso/registrationError';
import RegistrationStartedEvent from './models/sso/registrationStarted';
import RegistrationInitiatedEvent from './models/sso/registrationInitiated';
import ResetPasswordErrorEvent from './models/sso/resetPasswordError';
import ResetPasswordStartedEvent from './models/sso/resetPasswordStarted';
import ResetPasswordSubmitEvent from './models/sso/resetPasswordSubmit';
import ResetPasswordSuccessEvent from './models/sso/resetPasswordSuccess';

import AuthorableButtonClicked from './models/authorables/authorableButtonClicked';

import ButtonClicked from './models/buttonClicked';
import UserClick from './models/userClick';

const EDL_ARRAY_PATH = 'CEDL';

function initializeEDL() {
    if (!Utilities.object.hasDeepValue(window, EDL_ARRAY_PATH)) {
        Utilities.object.setDeepValue(window, EDL_ARRAY_PATH, []);
    }
}

export function pushEvent(evtInfo) {
    initializeEDL();

    const evts = Utilities.object.getDeepValue(window, EDL_ARRAY_PATH);

    // TODO: do proper format checks and throw warning if not proper format

    evts.push(evtInfo);


    if (window.dcxa_debug_edl) {
        console.log(evtInfo);
    }
}


export default {
    pushEvent,

    applications: {
        assets: {
            name: 'assets',
            features: {
                details: 'details',
                dashboard: 'dashboard',
                validations: 'validations',
                decomission: 'decomission',
                createAsset: 'createAsset',
                updateAsset: 'updateAsset',
                validationExport: 'validationExport'
            }
        },
        account: {
            name: 'account',
            features: {
                notificationSettings: 'notificationSettings'
            }
        },
        cases: {
            name: 'caseManagement',
            features: {
                caseListView: 'caseListView',
                caseCreation: 'caseCreation',
                caseExport: 'caseExport'
            }
        },
        sso: {
            name: 'sso',
            features: {
                registration: 'registration',
                setPassword: 'setPassword',
                resetPassword: 'forgotPassword',
                login: 'login'
            }
        },
        billing: {
            name: 'billingAndMetering',
            features: {
                usageDetails: 'usageDetails'
            }
        }
    },

    actions: {
        navigation: {
            name: 'navigation',
            values: {
                validationTabViewed: 'validationTabValidationViewed',
                emailSettings: 'emailSettingsNavigation',
                journeyAdvisorNavigation: 'journeyAdvisorNavigation',
                createNewAsset: 'newAssetNavigation',
                wxmNavigate: 'wxmNavigation',
                relatedCaseView: 'relatedCaseNavigation',
                viewAsset: 'assetDetails',
                decomission: 'decomission',
                decomissionAction : 'asset',
                caseListView: {
                    my_cases: 'myCases',
                    watching: 'watching',
                    my_account: 'myAccountCases',
                    validation: 'validationCases',
                    pending: 'pendingFutureRelease'
                }
            }
        },
        newTabNavigation: {
            name: 'newTabNavigation',
            values: {
                hepfulResources: 'helpfulResouceFromValidation'
            }
        }
    },


    models: {
        PageViewEventModel,
        DownloadInitiated,
        DownloadSuccessful,

        JourneyAdvisorPageLoaded,
        JourneyAdvisorAdviceLoadingError,
        JourneyAdvisorAccountDetailsLoadingError,
        JourneyAdvisorAssetLoadingError,
        JourneyAdvisorAssetViewRedirection,

        SearchRequested,
        SearchResultClicked,
        SuccessfulSearch,
        UnsuccessfulSearch,
        SearchFilter,

        HelpfulResourcesUpdate,
        HelpfulResourceClicked,
        CaseSubmitted,
        CaseSubmissionError,
        NotEntitledForCases,
        NewCaseInteraction,

        AuthorableButtonClicked,
        ButtonClicked,
        UserClick,

        IsMigratedUserEvent,
        LoginInitiatedEvent,
        LoginSuccessEvent,
        LoginErrorEvent,
        RegistrationStartedEvent,
        RegistrationInitiatedEvent,
        RegistrationCompletedEvent,
        RegistrationErrorEvent,
        ResetPasswordStartedEvent,
        ResetPasswordSubmitEvent,
        ResetPasswordSuccessEvent,
        ResetPasswordErrorEvent,

    }
}