import EventModelBase from "../eventModelBase";
import _ from 'lodash';

export default class LoginSuccessEvent extends EventModelBase {

    constructor(application, feature, profile, redirectUrl) {
        super('loginSuccessEvent');

        this.page = {
            ...this.page,
        }

        this.attributes = {
            ...this.attributes,
            company: profile.company,
            accountType: profile.AccountType,
            jobFunction: profile.jobFunction,
            jobRole: profile.jobRole,
            portalRoles: profile.portalRoles,
            destination: redirectUrl,

            sfdcContactId: profile.SFContactID,
            sfdcAccountId: profile.SFAccountID,
            oktaId: profile.OktaID,
            application: application,
            feature: feature
        };
    }


}