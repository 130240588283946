import spa from './spa/api.js'
import './spa/ajax_handlers.js'

function isAnalyticsReady() {
    return window._satellite; // && window._satellite.initialized;
}


function executeDirectCallRule(ruleName) {
    if (isAnalyticsReady()) {
        _satellite.track(ruleName);
    }
}



export default {
    spa: spa,
    api: {
        executeDirectCallRule
    }
}