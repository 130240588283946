import EventModelBase from "../eventModelBase";

export default class SearchRequestedEventModel extends EventModelBase {

    constructor(searchTerm, searchLocation) {
        super('searchRequested');

        this.page = {
            ...this.page,
        }

        this.attributes = {
            ...this.attributes,
        };

        this.search = {
            searchTerm: searchTerm,
            searchLocation: searchLocation,
        };
    }




}