import BM_SUBSCRIPTION_SUMMARY_FIELD_MAPPINGS from '../../config/bmSubscriptionSummary.fields.mapping.json';
import moment from "moment-timezone";
import Utilities from '../utilities';

export class BmSubscriptionSummary {

    constructor(subscriptionSummaryData) {
        const inputSubscriptionSummaryData = subscriptionSummaryData || {};

        Object.keys(BM_SUBSCRIPTION_SUMMARY_FIELD_MAPPINGS).forEach(fieldName => {
            if (inputSubscriptionSummaryData.hasOwnProperty(BM_SUBSCRIPTION_SUMMARY_FIELD_MAPPINGS[fieldName])) {
                const val = inputSubscriptionSummaryData[BM_SUBSCRIPTION_SUMMARY_FIELD_MAPPINGS[fieldName]];

                if (val == 'null' || val == null) {
                    this[fieldName] = '-';
                } else {
                    this[fieldName] = val;
                }
            }
        });

        if (this.StartDate) {
            this.StartDateFormatted = moment(this.StartDate).format('YYYY-MM-DD');
        }

        if (this.ActiveCommitmentStartDate) {
            this.ActiveCommitmentStartDateFormatted = moment(this.ActiveCommitmentStartDate).format('YYYY-MM-DD');
        }

        if (this.ActiveCommitmentEndDate) {
            this.ActiveCommitmentEndDateFormatted = moment(this.ActiveCommitmentEndDate).format('YYYY-MM-DD');
        }

        if (this.CumulativeBilledUsageDate) {
            this.CumulativeBilledUsageDateFormatted = moment(this.CumulativeBilledUsageDate).format('YYYY-MM-DD');
        }

        if (this.ActivePeriodStartDate) {
            this.ActivePeriodStartDateFormatted = moment(this.ActivePeriodStartDate).format('YYYY-MM-DD');
        }

        if (this.ActivePeriodEndDate) {
            this.ActivePeriodEndDateFormatted = moment(this.ActivePeriodEndDate).format('YYYY-MM-DD');
        }

        if (this.AsOfDate) {
            this.AsOfDateFormatted = moment(this.AsOfDate).format('YYYY-MM-DD');
        }

        if (this.TrialStartDate) {
            this.TrialStartDateFormatted = moment(this.TrialStartDate).format('YYYY-MM-DD');
        }

        if (this.TrialEndDate) {
            this.TrialEndDateFormatted = moment(this.TrialEndDate).format('YYYY-MM-DD');
        }

        if (typeof this.CurrentPeriodUsage != 'undefined') {
            this.CurrentPeriodUsageFormatted = Utilities.formatting.currency(this.CurrentPeriodUsage, this.Currency);
        }

        if (typeof this.TrialUsage != 'undefined') {
            this.TrialUsageFormatted = Utilities.formatting.currency(this.TrialUsage, this.Currency);
        }

        if (typeof this.ActiveCommitment != 'undefined') {
            this.ActiveCommitmentFormatted = Utilities.formatting.currency(this.ActiveCommitment, this.Currency);
        }

        if (typeof this.CumulativeBilledUsage != 'undefined') {
            this.CumulativeBilledUsageFormatted = Utilities.formatting.currency(this.CumulativeBilledUsage, this.Currency);
        }

        if (typeof this.EstimatedBalance != 'undefined') {
            this.EstimatedBalanceFormatted = Utilities.formatting.currency(this.EstimatedBalance, this.Currency);
        }

        if (typeof this.EstimatedCharge != 'undefined') {
            this.EstimatedChargeFormatted = Utilities.formatting.currency(this.EstimatedCharge, this.Currency);
        }

        this.IsPlanTypeSupported = false;

        if (this.PlanType) {
            let isPayg = this.PlanType.toUpperCase() === "PAY-AS-YOU-GO";
            let isTrial = this.PlanType.toUpperCase() === "TRIAL";
            let isPrepay = this.PlanType.toUpperCase() === "PREPAY";

            this.IsPAYG = isPayg;

            if (this.PlanStatus) {
                this.IsActiveTrial = isTrial && this.PlanStatus.toUpperCase() === "ACTIVE";
                this.IsExpiredTrial = isTrial && this.PlanStatus.toUpperCase() === "EXPIRED";

                this.IsActivePrepay = isPrepay && (this.PlanStatus.toUpperCase() === "ACTIVE" || (this.PlanStatus.toUpperCase() === "EXPIRED" && this.IsPrepayOverage === false));
                this.IsOveragePrepay = isPrepay && (this.PlanStatus.toUpperCase() === "OVERAGE" || (this.PlanStatus.toUpperCase() === "EXPIRED" && this.IsPrepayOverage === true));
            }

            this.IsPlanTypeSupported = isPayg || isTrial || isPrepay;
        }

        this.AreChildSubscriptionsAllowed = false;

        if (typeof this.HasRollupPlan != 'undefined' && typeof this.AccountRollupRole != 'undefined') {
            this.AreChildSubscriptionsAllowed = this.HasRollupPlan === true && this.AccountRollupRole.toUpperCase() === "PARENT";
        }
    }
}