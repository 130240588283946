import PubSub from 'pubsub-js'

export const Channels = PubSub;

export const AJAX_ATTEMPT = 'ajax.attempt';
export const AJAX_SUCCESS = 'ajax.success';
export const AJAX_FAILURE = 'ajax.failure';



export default {
    Channels
}