import axios from 'axios';
import DcxaUIException from './dcxaUIException'

async function get(url) {
    try {
        return await axios.get(url);
    }
    catch(e) {
        throw new DcxaUIException('AJAX 001', null, e);
    }
}

async function post(url, payload) {
    try {
        return await axios.post(url, payload);
    }
    catch(e) {
        throw new DcxaUIException('AJAX 002', null, e);
    }
}

export default {
    get,
    post
}