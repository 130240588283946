import EventModelBase from "../eventModelBase";

export default class SearchResultClickedEventModel extends EventModelBase {

    constructor(resultPosition, docId, docUrl, searchTerm) {
        super('searchResultClicked');

        this.page = {
            ...this.page,
        }

        this.attributes = {
            ...this.attributes,
        };

        this.search = {
            searchResultSelectedPosition: resultPosition,
            docID: docId,
            docURL: docUrl,
            searchTerm: searchTerm
        };
    }




}