import EventModelBase from "../eventModelBase";

export default class HelpfulResourceClicked extends EventModelBase {

    constructor(application, feature, action, resourseLink, position ) {
        super('helpfulResourceClicked');

        this.page = {
            ...this.page,
        }

        this.attributes = {
            ...this.attributes,
            application: application,
            feature: feature,

            action : action,
            actionValue: resourseLink,
            searchResultSelectedPosition: position
        };
    }



}