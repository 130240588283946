import { Case } from './case'
import { Asset } from './asset'
import { CaseComment, CaseCommentPrefixes } from './caseComment'
import { CaseAttachment } from './caseAttachment'
import { CaseEscalation } from './caseEscalation'
import { CaseTeamMember } from './caseTeamMember'
import { AccountContact } from './accountContact'
import { BmSubscriptionSummary } from "./bmSubscriptionSummary";
import { BmUsageDetail } from "./bmUsageDetail";
import { BmCreditBalance } from "./bmCreditBalance";
import { MdfAccrualData } from "./mdfAccrualData";
import { MdfWithdrawalData } from "./mdfWithdrawalData";
import { AccountTeamMember } from "./accountTeamMember";
import { UpgradeAdvisorValidation } from "./upgradeAdvisorValidation";
import { AssetValidation } from "./assetValidation";
import { AdminUser } from "./adminUser";
import { AdminUserHistory } from "./adminUserHistory";
import _ from 'lodash'

export default {
    Case: caseObj => new Case(caseObj),
    Cases: cases => _.map(cases, c => new Case(c)),
    convertAssetToSfdcModel: Asset.convertToSfdcProperties,
    convertAssetToInlineSfdcModel: Asset.convertToInlineSfdcProperties,
    Asset: assetObj => new Asset(assetObj),
    Assets: assets => _.map(assets, a => new Asset(a)),
    enrichAssetWithEosData: Asset.enrichWithEosData,
    CaseCommentPrefixes: CaseCommentPrefixes,
    CaseComment: caseCommentObj => new CaseComment(caseCommentObj),
    CaseComments: caseComments => _.map(caseComments, a => new CaseComment(a)),
    CaseAttachment: caseAttachmentObj => new CaseAttachment(caseAttachmentObj),
    CaseAttachments: caseAttachments => _.map(caseAttachments, a => new CaseAttachment(a)),
    CaseEscalation: caseEscalationObj => new CaseEscalation(caseEscalationObj),
    CaseEscalations: caseEscalations => _.map(caseEscalations, a => new CaseEscalation(a)),
    convertCaseEscalationToSfdcModel: CaseEscalation.convertToSfdcProperties,
    CaseTeamMember: caseTeamMemberObj => new CaseTeamMember(caseTeamMemberObj),
    CaseTeam: caseTeam => _.map(caseTeam, a => new CaseTeamMember(a)),
    AccountContact: accountContactObj => new AccountContact(accountContactObj),
    AccountContacts: accountContacts => _.map(accountContacts, a => new AccountContact(a)),
    BmUsageDetails: usageDetails => _.map(usageDetails, d => new BmUsageDetail(d)),
    convertBmUsageDetailFieldToSfdcProperty: BmUsageDetail.convertFieldNameToSfdcProperty,
    BmSubscriptionSummary: subsriptionSummaryObj => new BmSubscriptionSummary(subsriptionSummaryObj),
    BmCreditBalance: creditBalanceObj => new BmCreditBalance(creditBalanceObj),
    MdfAccrualData: accrualData => _.map(accrualData, d => new MdfAccrualData(d)),
    MdfWithdrawalData: withdrawalData => _.map(withdrawalData, d => new MdfWithdrawalData(d)),
    AccountTeamMember: accountTeamMemberObj => new AccountTeamMember(accountTeamMemberObj),
    AccountTeamMembers: accountTeamMembers => _.map(accountTeamMembers, a => new AccountTeamMember(a)),
    UpgradeAdvisorValidation: upgradeAdvisorValidation => new UpgradeAdvisorValidation(upgradeAdvisorValidation),
    AssetValidation: assetValidation => new AssetValidation(assetValidation),
    AssetValidations: assetValidations => _.map(assetValidations, v => new AssetValidation(v)),
    AdminUser: adminUser => new AdminUser(adminUser),
    AdminUsers: adminUsers => _.map(adminUsers, u => new AdminUser(u)),
    AdminUserHistory: adminUserHistory => _.map(adminUserHistory, h => new AdminUserHistory(h))
}