function validator(inputVal, $securityQuestionInput) {
    return new Promise((res) => {
        const val = inputVal.toUpperCase().trim();

        $securityQuestionInput = $securityQuestionInput || '';
        const secQuestionText = (typeof $securityQuestionInput === 'string') ? $securityQuestionInput : ($securityQuestionInput.find('option:selected').text() || '');
        const secQuestion = secQuestionText.toUpperCase().replace(/[.,\/#!?$%\^&\*;:{}=\-_`~()]/g, "");

        const state = {
            details: {}
        }

        let result = true;

        state.details.minlength = true;
        if (val.length < 4) {
            result = false;
            state.details.minlength = false;
        }

        const questionWords = secQuestion.split(' ');
        const answerWords = val.split(' ');
        state.details.questionWord = _.every(questionWords, (qWord) => {
            return _.every(answerWords, (ansWord) => {
                return ansWord !== qWord;
            })
        });
        if (!state.details.questionWord) {
            result = false;
        }

        // rules: min length 4 char, questionWord check
        res({
            result: result,
            state: state
        });
    });
}

function hintParser(text, state) {

    const listAndState =
        '<ul class="tooltip-list no-bullets">' +
        '<li class="' + (state.details.minlength ? 'valid' : 'invalid') + '">Be at least 4 characters</li>' +
        '<li class="' + (state.details.questionWord ? 'valid' : 'invalid') + '">Cannot contain any word present in the security question</li>' +
        '</ul>';

    return text.replace('<<<REQUIREMENT_LIST_AND_STATE>>>', listAndState);
}

export default {
    validator: validator,
    validationErrorHint: 'Security answer must meet the following requirements: <br/><<<REQUIREMENT_LIST_AND_STATE>>>',
    hintParser: hintParser
}