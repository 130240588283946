import _ from 'lodash';

import Api from '../api/api'

import MOCK_SEARCH_DATA from '../../mocks/search_payload.json'
import { extractSearchResults } from './helpers'

const doSearch = _.debounce(search, 3000);

async function search(queryParams) {
    let searchResponse;
    let hasError = false;
    let errorCode = null;
    try {
        const payload = {
            "data": new URLSearchParams(queryParams).toString()
        }
        searchResponse = await Api.callEndpoint("GET_HELPFUL_RESOURCES", { payload } )
        // check for errors
        if (searchResponse.error) {
            hasError = true;
            throw searchResponse.error;
        }
    } catch (e) {
        hasError = true;
        searchResponse = MOCK_SEARCH_DATA;
        errorCode = e.skynetErrorCode;
    }

    const searchResults = extractSearchResults(searchResponse, 'boomi-hr');
    const ret = {
        results: searchResults,
        hasError: hasError,
        errorCode: errorCode
    };
    return ret;
}

export default {
    search: doSearch
}