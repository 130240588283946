import Utilities from './utilities'

function isDnap() {
    const accountName = Utilities.object.getDeepValue(Cloudera, 'Analytics.RequestDescriptor.actor.accountName');
    const status = (accountName === 'Do Not Auto-Provision' || accountName === 'Awaiting Account Assignment');
    const message = "We were unable to associate your profile to the correct account so some features may be temporarily unavailable to you. Please have someone on your team with case access file a support ticket to complete your profile's account association.";

    return {
        status: status,
        message: message
    }

}



export default {
    isDnap: isDnap
}