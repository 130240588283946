export const BASE_SEARCH_URL = '/search'
export const BASE_KB_URL = '/content/support/my/en-us/knowledge'

export const COLLECTIONS = {
    UNIFIED: 'unified-search',
    DOCS: 'documentation',
    KB: 'kb',
    COMMUNITY: 'community',
    BOOMI_HR: 'boomi-hr'
}

export const COVEO_FIELD_MAPPING = {
    [COLLECTIONS.UNIFIED]: {
        'uniqueId': 'id',
        'title': 'title',
        'excerpt': 'teaser',
        'raw.sourcetype': 'type',
        'raw.date': 'published'
    },
    [COLLECTIONS.DOCS]: {
        'id': 'id',
        'title': 'title',
        'excerpt': 'teaser',
        'raw.sourcetype': 'type',
        'raw.date': 'published'
    },
    [COLLECTIONS.KB]: {
        'id': 'id',
        'title': 'title',
        'excerpt': 'teaser',
        'raw.sourcetype': 'type',
        'raw.date': 'published'
    },
    [COLLECTIONS.COMMUNITY]: {
        'id': 'id',
        'title': 'title',
        'excerpt': 'teaser',
        'raw.sourcetype': 'type',
        'raw.date': 'published'
    },
    [COLLECTIONS.BOOMI_HR]: {
        'id': 'view_href',
        'subject': 'subject',
        'teaser': 'teaser',
        'content_type': 'content_type',
    }
}

export const FIELD_MAPPING = {
    [COLLECTIONS.UNIFIED]: {
        'id': 'id',
        'title': 'title',
        'teaser': 'teaser',
        'content_type': 'type',
        'post_time': 'published'
    },
    [COLLECTIONS.DOCS]: {
        'id': 'id',
        'title': 'title',
        'teaser': 'teaser',
        'content_type': 'type',
        'post_time': 'published'
    },
    [COLLECTIONS.KB]: {
        'id': 'id',
        'subject': 'title',
        'teaser': 'teaser',
        'content_type': 'type',
        'post_time': 'published'
    },
    [COLLECTIONS.COMMUNITY]: {
        'id': 'id',
        'subject': 'title',
        'teaser': 'teaser',
        'content_type': 'type',
        'post_time': 'published'
    },
    [COLLECTIONS.BOOMI_HR]: {
        'id': 'view_href',
        'subject': 'subject',
        'teaser': 'teaser',
        'content_type': 'content_type',
    }
}

export const UI_SETTINGS = {
    [COLLECTIONS.UNIFIED]: {
        visibleName: ''
    },
    [COLLECTIONS.DOCS]: {
        visibleName: 'Documentation',
        iconClass: 'documentation-icon'
    },
    [COLLECTIONS.KB]: {
        visibleName: 'Knowledge Base',
        iconClass: 'knowledge-base-icon'
    },
    [COLLECTIONS.COMMUNITY]: {
        visibleName: 'Community',
        iconClass: 'community-icon'
    },
}

export const RESULT_SET_PATH = 'response.docs';
export const FACET_INFO_PATH = 'facet_counts.facet_fields.content_facets_string';

// This is used for sorting root level nodes of facets tree view component
// The order of the items matter
export const ENABLED_FACETS = ['Product', 'Component', 'Context'];

export const PAGE_SIZES = [10, 25, 50];
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE = 1;
export const DEFAULT_COLLECTION = COLLECTIONS.UNIFIED;

export const MAX_COVEO_RETURN_SET = 5000;