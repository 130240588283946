import BM_USAGE_DETAIL_FIELD_MAPPINGS from '../../config/bmUsageDetail.fields.mapping.json';

import Utilities from '../utilities';
import moment from "moment-timezone";
import _ from "lodash";

export class BmUsageDetail {

    constructor(usageDetailsData) {
        const inputUsageDetailsData = usageDetailsData || {};

        Object.keys(BM_USAGE_DETAIL_FIELD_MAPPINGS).forEach(fieldName => {
            if (inputUsageDetailsData.hasOwnProperty(BM_USAGE_DETAIL_FIELD_MAPPINGS[fieldName])) {
                const val = inputUsageDetailsData[BM_USAGE_DETAIL_FIELD_MAPPINGS[fieldName]];

                if (val === 'null' || val === null || val === "") {
                    this[fieldName] = '-';
                } else {
                    this[fieldName] = val;
                }
            }
        });

        if (this.ServiceFeature === 'DataFlow Functions') {
            this.Hours = '-';
            this.InstanceType = '-';
            this.InstanceCount = '-';
            this.EnvironmentName = '-';
            this.EnvironmentCrn = '-';
        }

        if (this.StartDate) {
            this.StartDateFormatted = moment(this.StartDate).format('YYYY-MM-DD');
        }

        if (this.StartTime) {
            this.StartTimeFormattedWithoutSeconds = this.StartTime.substring(0, this.StartTime.lastIndexOf(':'));
            this.StartTimeFormattedWithSeconds = this.StartTime;
        }

        if (this.EndDate) {
            this.EndDateFormatted = moment(this.EndDate).format('YYYY-MM-DD');
        }

        if (this.EndTime) {
            this.EndTimeFormattedWithoutSeconds = this.EndTime.substring(0, this.EndTime.lastIndexOf(':'));
            this.EndTimeFormattedWithSeconds = this.EndTime;
        }

        if (typeof this.Hours != 'undefined') {
            this.HoursFormatted = Utilities.formatting.numberWithCommas(this.Hours, 0, 4);
        }

        if (typeof this.Quantity != 'undefined') {
            this.QuantityFormatted = Utilities.formatting.numberWithCommas(this.Quantity, 0, 4);
        }

        if (typeof this.ListRate != 'undefined') {
            this.ListRateFormatted = Utilities.formatting.currency(this.ListRate, this.Currency, false, 4, 4);
        }

        if (typeof this.DiscountedRate != 'undefined') {
            this.DiscountedRateFormatted = Utilities.formatting.currency(this.DiscountedRate, this.Currency, false, 4, 4);
        }

        if (typeof this.EffectiveCharge != 'undefined') {
            this.EffectiveChargeFormatted = Utilities.formatting.currency(this.EffectiveCharge, this.Currency);
        }

        if (typeof this.GrossCharge != 'undefined') {
            this.GrossChargeFormatted = Utilities.formatting.currency(this.GrossCharge, this.Currency);
        }
    }

    static convertFieldNameToSfdcProperty(uiFieldName) {
        if (BM_USAGE_DETAIL_FIELD_MAPPINGS.hasOwnProperty(uiFieldName)) {
            return BM_USAGE_DETAIL_FIELD_MAPPINGS[uiFieldName];
        }

        return uiFieldName;
    }
}