import EventModelBase from "../eventModelBase";
import _ from 'lodash';

export default class ResetPasswordSuccessEvent extends EventModelBase {

    constructor(application, feature, email) {
        super('resetPasswordSuccessEvent');

        this.page = {
            ...this.page,
        }

        this.attributes = {
            ...this.attributes,
            userEnteredEmail: email,
            application: application,
            feature: feature
        };
    }


}