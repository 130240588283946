import EventModelBase from "../eventModelBase";
import _ from 'lodash';

export default class ResetPasswordErrorEvent extends EventModelBase {

    constructor(application, feature,skynetErrorCode, clientSideErrorCode='') {
        super('resetPasswordErrorEvent');

        this.page = {
            ...this.page,
        }

        this.attributes = {
            ...this.attributes,
            clientSideErrorCode: clientSideErrorCode,
            skynetErrorCode: skynetErrorCode,
            application: application,
            feature: feature
        };
    }


}