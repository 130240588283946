(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("dcxa_axios"), require("lodash"), require("dcxa_moment"), require("dcxa_pubsub"));
	else if(typeof define === 'function' && define.amd)
		define(["dcxa_axios", "lodash", "dcxa_moment", "dcxa_pubsub"], factory);
	else if(typeof exports === 'object')
		exports["dcxaCoreLibrary"] = factory(require("dcxa_axios"), require("lodash"), require("dcxa_moment"), require("dcxa_pubsub"));
	else
		root["dcxaCoreLibrary"] = factory(root["dcxa_axios"], root["_"], root["dcxa_moment"], root["dcxa_pubsub"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_axios__, __WEBPACK_EXTERNAL_MODULE_lodash__, __WEBPACK_EXTERNAL_MODULE_moment__, __WEBPACK_EXTERNAL_MODULE_pubsub_js__) {
return 