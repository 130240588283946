import EventModelBase from "../eventModelBase";

export default class CaseSubmitted extends EventModelBase {

    constructor(application, feature, caseId) {
        super('caseSubmitted');

        this.page = {
            ...this.page,
        }

        this.attributes = {
            ...this.attributes,
            application: application,
            feature: feature,

            caseNumber  : caseId
        };
    }


}