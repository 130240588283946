import _ from 'lodash';

import Api from '../api/api'
import Configs from '../configs'
import Utilities from '../utilities'

/* develblock:start */
import MOCK_SEARCH_DATA from '../../mocks/searchResults.json'
import MOCK_COVEO_SEARCH_DATA from '../../mocks/searchResultsCoveo.json'
/* develblock:end */

import { BASE_SEARCH_URL, BASE_KB_URL, DEFAULT_PAGE, PAGE_SIZES, DEFAULT_PAGE_SIZE, DEFAULT_COLLECTION, COLLECTIONS, FIELD_MAPPING, RESULT_SET_PATH, FACET_INFO_PATH, ENABLED_FACETS, UI_SETTINGS } from './consts'
import { extractCollectionInfoFromQuery, normalizeTerm, buildFacetsQueryParameter, extractFacetsFromQueryParameter, extractSearchResults, extractMetadata, extractFacetInformation } from './helpers'

function buildAllKbArticlesSearchQuery(term) {
    return normalizeTerm(term) + '&collection=' + COLLECTIONS.KB + '&rows=' + DEFAULT_PAGE_SIZE + '&start=0';
}

function buildRecentlyEditedKbArticlesSearchQuery(start, rows = DEFAULT_PAGE_SIZE) {
    start = start || 0;
    return normalizeTerm('') + '&collection=' + COLLECTIONS.KB + '&start=' + start + '&rows=' + rows + '&sort=last_edit_time desc'
}

function buildFacetedKbSearchQuery(term, facets) {
    return normalizeTerm(term) +
        '&collection=' + COLLECTIONS.KB +
        '&start=' + 0 +
        '&rows=' + DEFAULT_PAGE_SIZE +
        buildFacetsQueryParameter(facets);
}

function buildDynamicQuery(collection, term, start, rows, facets, extraParams) {
    rows = rows || DEFAULT_PAGE_SIZE;
    return normalizeTerm(term) +
        '&collection=' + collection +
        '&start=' + start +
        '&rows=' + rows +
        buildFacetsQueryParameter(facets) +
        (extraParams ? extraParams : '')
}

function parseQuery(query) {
    // get rid of ?
    if (query.indexOf('?') == 0) {
        query = query.substring(1);
    }

    let facets = [];
    const url = new URLSearchParams(query);

    if (url.getAll('fq').length > 1) {
        // coming from docs
        const fqs = url.getAll('fq');
        const q = url.get('q');

        try {
            facets.push(fqs[1].split(':')[1].replace(new RegExp('"', 'g'), ''));
        } catch (e) {

        }

        return {
            term: q,
            collection: COLLECTIONS.DOCS,
            rows: Number(url.get('rows')) || DEFAULT_PAGE_SIZE,
            start: Number(url.get('start')) || 0,
            facets: facets
        }

    } else {
        // normal search
        const parts = query.split('&');
        const searchTerm = parts[0];

        if (url.has('fq')) {
            facets = extractFacetsFromQueryParameter(url.get('fq'));
        }

        return {
            term: searchTerm,
            collection: url.get('collection') || DEFAULT_COLLECTION,
            rows: Number(url.get('rows')) || DEFAULT_PAGE_SIZE,
            start: Number(url.get('start')) || 0,
            facets: facets
        }
    }

}


async function doSearch(query) {
    let searchResponse;
    let hasError = false;

    try {
        searchResponse = await Api.callEndpoint("SKYNET_SEARCH_ENDPOINT", { payload: { data: query } });

        // check if there was any error 
        if (searchResponse.error) {
            hasError = true;
            throw searchResponse.error;
        }
    } catch (e) {
        // TODO: normalize AJAX error handling
        hasError = true;
        /* develblock:start */
        searchResponse = Utilities.feature.get(Utilities.feature.consts.useCoveoSearch) === true ? MOCK_COVEO_SEARCH_DATA : MOCK_SEARCH_DATA;
        /* develblock:end */
    }

    /* develblock:start */
    console.log('search response: ');
    console.log(searchResponse);
    /* develblock:end */

    const collection = extractCollectionInfoFromQuery(query);

    const searchResults = extractSearchResults(searchResponse, collection);
    const selectedFacets = parseQuery(query).facets;
    const facets = extractFacetInformation(searchResponse, selectedFacets);
    const metadata = extractMetadata(searchResponse);


    const ret = {
        results: searchResults,
        facets: facets,
        metadata: metadata,
        hasError: hasError
    };

    /* develblock:start */
    console.log(ret);
    /* develblock:end */

    return ret;
}

function redirectToSearch(query) {
    window.location = BASE_SEARCH_URL + '?' + query;
}

function redirectToKb() {
    window.location = BASE_KB_URL;
}


export default {
    queries: {
        dynamic: buildDynamicQuery,

        unified: {

        },
        kb: {
            all: buildAllKbArticlesSearchQuery,
            recentlyEdited: buildRecentlyEditedKbArticlesSearchQuery,
            faceted: buildFacetedKbSearchQuery
        },

    },
    parse: parseQuery,

    search: doSearch,
    redirectToSearch: redirectToSearch,
    redirectToKb: redirectToKb,
    consts: {
        searchUrl: BASE_SEARCH_URL,
        defaultCollection: DEFAULT_COLLECTION,
        collections: COLLECTIONS,
        collectionUiSettings: UI_SETTINGS,
        defaultPage: DEFAULT_PAGE,
        defaultPageSize: DEFAULT_PAGE_SIZE,
        pageSizes: PAGE_SIZES
    }
}