import EventModelBase from "../eventModelBase";
import _ from 'lodash';

export default class LoginInitiatedEvent extends EventModelBase {

    constructor(application, feature, email) {
        super('loginInitiatedEvent');

        this.page = {
            ...this.page,
        }

        this.attributes = {
            ...this.attributes,
            application: application,
            feature: feature
        };

        this.sso = {
            userEmail : email
        };
    }


}