const LEAD_STORAGE_KEY = 'dcxa-pp-lead';
const LEAD_QUERY_STRING_KEY = 'lead';
const MDF_ROLES = [
    'partner-silver',
    'partner-gold',
    'partner-platinum'
];
const PARTNER_DEVELOPER_ROLE = 'partner-developer';

export default {
    consts: {
        leadStorageKey: LEAD_STORAGE_KEY,
        leadQueryStringKey: LEAD_QUERY_STRING_KEY,
        mdfRoles: MDF_ROLES,
        partnerDeveloperRole: PARTNER_DEVELOPER_ROLE
    }
}