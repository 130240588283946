import Utilities from './utilities'
import Ajax from './ajax'
import DcxaUIException from './api/dcxaUIException'

const RESPONSE_ERROR_CODE_PATH = 'errorCode';
const GENERAL_SKYNET_ERROR_CODE = 'G01';
const MYC_404_PAGE = '/content/support/my/en-us/errors/404.html';

const SPECIAL_SKYNET_ERROR_HANDLERS = {
    'RF03': function() {
        Utilities.url.redirect();
        return true;
    },
    'AB-03': function() {
        Utilities.url.redirect();
        return true;
    },
    'OB04': function() {
        Utilities.url.redirect();
        return true;
    },
    'RO17': function() {
        Utilities.url.redirect();
        return true;
    },
    'KB01': function() {
        Utilities.url.redirectTo(MYC_404_PAGE, true);
        return true;
    },
    'KB03': function() {
        Utilities.url.redirectTo(MYC_404_PAGE, true);
        return true;
    }
}

function validateSkynetErrorFormat(error) {
    if (!Ajax.validation.skynet.responseFormatValidator(error)) {
        return false;
    }
    if (!Ajax.validation.skynet.statusValidator(error, 300)) {
        return false;
    }
    if (!Utilities.object.hasDeepValue(error, 'payload.errorMessage')) {
        return false;
    }

    return true;
}

function createSkynetUIError(error) {
    error = error || {};
    const mappedErrorObj = transformSkynetErrorObject(error.response);

    // In case of redirections of special error handlers (null return object), no need to handle exception on higher level
    if (mappedErrorObj) {
        return new DcxaUIException(mappedErrorObj.clientErrorCode, mappedErrorObj.skynetErrorCode, undefined, Utilities.object.getDeepValue(error, 'response.status'));
    }
}

function transformSkynetErrorObject(errorObj) {

    if (!errorObj || (errorObj.request && errorObj.request.readyState === 0)) {
        return { clientErrorCode: 'CSG 002' };
    }

    const response = errorObj.data;

    if (!validateSkynetErrorFormat(response)) {
        return { clientErrorCode: 'SKY 002' };
    }

    const skynetErrorCode = Utilities.object.getDeepValue(response, RESPONSE_ERROR_CODE_PATH) || GENERAL_SKYNET_ERROR_CODE;

    const specialErrorHandler = Utilities.object.getDeepValue(SPECIAL_SKYNET_ERROR_HANDLERS, skynetErrorCode);
    if (specialErrorHandler && typeof specialErrorHandler === 'function') {

        if (window.location.host.indexOf('-auth') < 0 && specialErrorHandler()) {
            return null;
        }
    }

    return {
        skynetErrorCode: skynetErrorCode,
        clientErrorCode: Ajax.utilities.mapSkynetErrorToClientError(skynetErrorCode)
    };
}





/////////////////////////////////////////////
// EXPORTS
/////////////////////////////////////////////
export default {

    handlers: {
        createSkynetUIError: createSkynetUIError
    }
}