import EventModelBase from "./eventModelBase";

export default class UserClickModel extends EventModelBase {

    constructor(application, feature, action, actionValue, extra) {
        super('userClick');

        this.page = {
            ...this.page,
        }

        this.attributes = {
            ...this.attributes,
            application,
            feature,

            action,
            actionValue,
            
            ...extra
        };
    }




}