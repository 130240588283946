import ADMIN_USER_FIELD_MAPPINGS from '../../config/adminUser.fields.mapping.json';

import Utilities from '../utilities';
import moment from "moment-timezone";

export class AdminUser {

    constructor(userData) {
        const inputUserData = userData || {};

        Object.keys(ADMIN_USER_FIELD_MAPPINGS).forEach(fieldName => {
            if (inputUserData.hasOwnProperty(ADMIN_USER_FIELD_MAPPINGS[fieldName])) {
                const val = inputUserData[ADMIN_USER_FIELD_MAPPINGS[fieldName]];

                if (val === 'null' || val === null || val === "") {
                    this[fieldName] = '-';
                } else {
                    this[fieldName] = val;
                }
            }
        });

        if (!this.UserDetails) {
            this.FullName = this.firstName + ' ' + this.lastName;
            this.IsMembeOfAccount = this.nativeAccount !== null && this.nativeAccountName !== null;
            this.IsMemberOfSfdc = this.sdfcContactId !== null;
            this.LastLoginFormatted = moment(this.lastLogin).format('YYYY-MM-DD');
        }

        if (this.UserDetails) {
            this.FullName = this.UserDetails.firstName + ' ' + this.UserDetails.lastName;
            this.IsMembeOfAccount = this.UserDetails.nativeAccount !== null && this.UserDetails.nativeAccountName !== null;
            this.IsMemberOfSfdc = this.UserDetails.sdfcContactId !== null;
        }

        if (this.OktaInformation) {
            this.LastLoginFormatted = moment(this.OktaInformation.lastLogin).format('YYYY-MM-DD');
        }

        if (this.Accounts) {
            this.HasAssignedAccounts = this.Accounts.length > 0;
        }
    }
}