import Api from '../../api/api'

function validator(val, email, contactId) {
    return new Promise(async(res) => {

        if (!val || val.length == 0) {
            res({
                result: true,
                state: {}
            });
            return;
        }

        let validity = true;

        const state = {
            details: {
                emailDontMatch: false
            }
        };

        try {
            
            const response = await Api.callEndpoint("SKYNET_CHECK_CONTACT_ID_EMAIL", { payload: { email, contactId } });

            let emailContactMatch = validity = response;

            state.details.emailContactMatch = emailContactMatch;
            validity = emailContactMatch;


        } catch (e) {
            // Handle exception here
            state.details.emailContactMatch = false;
            validity = false;
        }

        res({
            result: validity,
            state: state
        });
    });
}



export default {
    validator: validator,
    validationErrorMessage: 'The email does not match the SFDC contact record',
    validationErrorHint: ''
}