import Utilities from './utilities'

import skynetErrorMappingConfig from '../config/skynet_error_mapping.json';
import clientErrorMappingConfig from '../config/errors.json';

const SKYNET_ERROR_MAPPING = skynetErrorMappingConfig;
const CLIENT_ERROR_MAPPING = clientErrorMappingConfig;

const RESPONSE_PAYLOAD_PATH = 'payload';

/////////////////////////////////////////////
// VALIDATION
/////////////////////////////////////////////


function commonResponseFormatValidation(response) {
    if (!Utilities.object.hasDeepValue(response, 'payload')) {
        console.warn('No payload defined in the response object.');
        console.warn(response);
        return false;
    }
    if (!Utilities.object.hasDeepValue(response, 'status')) {
        console.warn('No status defined in the response object.');
        console.warn(response);
        return false;
    }
    if (!Utilities.object.hasDeepValue(response, 'timestamp')) {
        console.warn('No timestamp defined in the response object.');
        console.warn(response);
        return false;
    }
    return true;
}

function statusValidaton(response, lowerLimit, upperLimit) {
    const respStatus = Utilities.object.getDeepValue(response, 'status');
    if (respStatus && lowerLimit && respStatus < lowerLimit) {
        console.warn('Response status code should be higher than ' + lowerLimit + '.');
        console.warn(response);
        return false;
    }
    if (respStatus && upperLimit && respStatus > upperLimit) {
        console.warn('Response status code should be less than ' + upperLimit + '.');
        console.warn(response);
        return false;
    }
    return true;
}

/////////////////////////////////////////////
// EXTRACT
/////////////////////////////////////////////

function extractPayloadFromResponse(response) {
    return Utilities.object.getDeepValue(response, RESPONSE_PAYLOAD_PATH);
}

/////////////////////////////////////////////
// UTILITIES
/////////////////////////////////////////////

function mapSkynetErrorToClientError(skynetErrorCode) {
    return SKYNET_ERROR_MAPPING[skynetErrorCode] || SKYNET_ERROR_MAPPING['default'];
}

function mapClientErrorToConfigObject(clientErrorCode) {
    return CLIENT_ERROR_MAPPING[clientErrorCode] || CLIENT_ERROR_MAPPING['default'];
}

/////////////////////////////////////////////
// EXPORTS
/////////////////////////////////////////////
export default {
    utilities: {
        mapSkynetErrorToClientError: mapSkynetErrorToClientError,
        mapClientErrorToConfigObject: mapClientErrorToConfigObject
    },

    validation: {
        skynet: {
            responseFormatValidator: commonResponseFormatValidation,
            statusValidator: statusValidaton
        },
    },
    extract: {
        payloadFromResponse: extractPayloadFromResponse,
    }
}