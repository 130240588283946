const CASE_ATTACHMENT_FIELD_MAPPINGS = require('../../config/caseAttachment.fields.mapping.json');

export class CaseAttachment {
  constructor(caseAttachmentData) {
    const inputCaseAttachmentData = caseAttachmentData || {};

    Object.keys(CASE_ATTACHMENT_FIELD_MAPPINGS).forEach((fieldName) => {
      if (
        inputCaseAttachmentData.hasOwnProperty(
          CASE_ATTACHMENT_FIELD_MAPPINGS[fieldName],
        )
      ) {
        const val =
          inputCaseAttachmentData[CASE_ATTACHMENT_FIELD_MAPPINGS[fieldName]];

        if (val === 'null') {
          this[fieldName] = null;
        } else {
          this[fieldName] = val;
        }
      }
    });

    if (this.CreatedBy && this.CreatedBy.Name) {
      this.author = this.CreatedBy.Name;
    }
    if (this.Case && this.Case.CaseNumber) {
      this.caseId = this.Case.CaseNumber;
    }
  }
}
