const numberRegex = "^[1-9]\\d{0,<<NUMBER_LENGTH>>}((\\.){1}(\\d){<<MIN_DECIMAL_LENGTH>>,<<DECIMAL_LENGTH>>})?$";
// const startsWith0Regex = "^0\\.(\\d){<<MIN_DECIMAL_LENGTH>>,<<DECIMAL_LENGTH>>}$";
const startsWith0Regex = "^0((\\.){1}(\\d){<<MIN_DECIMAL_LENGTH>>,<<DECIMAL_LENGTH>>})?$";

const onlyNumberAndDecimalRegex = /^([0-9.])*$/;
// const onlyNumberAndDecimalRegex = /^([0-9])+((.){1}([0-9])+)?$/;

function validator(value, isRequired = false, n = 18, d = 0) {

    return new Promise((res) => {
        value = value || '';

        let currRegexString = numberRegex;
        currRegexString = currRegexString.replace('<<NUMBER_LENGTH>>', n - 1);
        currRegexString = currRegexString.replace('<<MIN_DECIMAL_LENGTH>>', (d > 0) ? 1 : 99999);
        currRegexString = currRegexString.replace('<<DECIMAL_LENGTH>>', (d > 0 ? d : 99999));

        let currStartsWith0Regex = startsWith0Regex.replace('<<MIN_DECIMAL_LENGTH>>', (d > 0) ? 1 : 99999);
        currStartsWith0Regex = currStartsWith0Regex.replace('<<DECIMAL_LENGTH>>', (d > 0 ? d : 99999));

        const regexp = new RegExp(currRegexString);

        let result = regexp.test('' + value);

        if (!isRequired && ('' + value).length == 0) {
            result = true;
        }

        const valid0StartingNumber = new RegExp(currStartsWith0Regex).test('' + value);
        result = result || valid0StartingNumber;

        const details = {};

        details.containsInvalidChars = !(new RegExp(onlyNumberAndDecimalRegex).test('' + value));
        details.maxLength = ('' + value).split('.')[0].length > n;
        details.decimalLength = (('' + value).split('.')[1] || '').length > d;
        details.strangeNumber = !result;

        res({
            result: result,
            state: { details }
        });
    });
}

export default {
    validator: validator,
    validationErrorMessage: '',
    messageParser: function(msg, state) {
        if (state.details.containsInvalidChars) {
            return 'Field can contain only numbers and decimal points.'
        }
        if (state.details.maxLength) {
            return 'Field can contain only {{1}} digits.'
        }
        if (state.details.decimalLength) {
            return 'Field can only contain numbers with up to {{2}} decimal places.'
        }
        if (state.details.strangeNumber) {
            return 'The number does not seem to be valid.'
        }

        return "Invalid number.";
    }
}