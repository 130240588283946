import _ from 'lodash'
import STATIC_NOTIFICATIONS from '../config/sample/staticNotifications.json'

function initializeStaticNotifications(currUrl, notificationList) {
    const currDate = new Date();

    notificationList = notificationList || STATIC_NOTIFICATIONS;

    const activeNotifications = _.filter(STATIC_NOTIFICATIONS, sn => {
        const regexp = new RegExp(sn.urlRegexp);
        return regexp.test(currUrl) && new Date(sn.from) <= currDate && new Date(sn.to) >= currDate;
    });

    console.log(activeNotifications);

    window.Cloudera = window.Cloudera || {};
    window.Cloudera.Runtime = window.Cloudera.Runtime || {};
    window.Cloudera.Runtime.Notifications = window.Cloudera.Runtime.Notifications || {};

    window.Cloudera.Runtime.Notifications.activeNotifications = activeNotifications;
}



export default {
    initializeStaticNotifications: initializeStaticNotifications
}