import Api from '../api/api'
import Utilities from '../utilities'

import { BASE_SEARCH_URL, COLLECTIONS, COVEO_FIELD_MAPPING, FIELD_MAPPING, RESULT_SET_PATH, FACET_INFO_PATH, ENABLED_FACETS, MAX_COVEO_RETURN_SET } from './consts'
import _ from "lodash";

export function normalizeTerm(term) {
    let emptyCharacter = Utilities.feature.get(Utilities.feature.consts.useCoveoSearch) === true ? '' : '*';
    return ((term || '').length > 0 ? term : emptyCharacter).replace(/&/g, '');
}

export function buildFacetsQueryParameter(facets) {
    return ((facets.length > 0) ?
        '&fq=' + _.map(facets, (f) => { return 'content_facets_string: \"' + f + '\"' }).join(' AND ') :
        '');
}

export function extractFacetsFromQueryParameter(facetsString) {
    const facets = [];
    const rawFacets = facetsString.split(' AND ');

    _.forEach(rawFacets, (f) => {
        const facetStartString = ': \"';
        const facetEndString = '\"';

        const facetStartIndex = f.indexOf(facetStartString);
        const facetEndIndex = f.lastIndexOf(facetEndString);

        const facet = f.substring(facetStartIndex + facetStartString.length, facetEndIndex);

        facets.push(facet);
    });

    return facets;
}

export function extractCollectionInfoFromQuery(query) {
    const parts = query.split('collection=');
    if (parts.length < 2) {
        return 'unified-search';
    }
    const text = parts[1];
    if (text.indexOf('&') == -1) {
        return text;
    }
    return text.substring(0, text.indexOf('&'));
}

export function extractMetadata(payload) {
    if (!payload) {
        return {
            resultSetSize: 0,
            contentTypeCounts: {}
        };
    }

    const contentTypeCountNumber = {};
    let resultSetSumBasedOnContentTypes = 0;

    if (Utilities.feature.get(Utilities.feature.consts.useCoveoSearch) === true) {
        const facetsInfo = Utilities.object.getDeepValue(payload, 'facets');
        const contentTypes = _.find(facetsInfo, f => f['facetId'] == '@content_types');
        if (contentTypes) {
            contentTypes['values'].forEach(ct => {
                let number = ct['numberOfResults'] | 0;
                number = Math.min(number, MAX_COVEO_RETURN_SET);

                let typeId = ct['value'].toLowerCase();

                resultSetSumBasedOnContentTypes += number;
                contentTypeCountNumber[typeId] = number;
            });
        }

        resultSetSumBasedOnContentTypes = Math.min(resultSetSumBasedOnContentTypes, MAX_COVEO_RETURN_SET);
    } else {
        const contentTypeCounts = Utilities.object.getDeepValue(payload, 'facet_counts.facet_fields.content_type') || [];
        let N = contentTypeCounts.length / 2;
        let i = 0;

        while (i < N) {
            const typeId = contentTypeCounts[i * 2];
            const number = contentTypeCounts[i * 2 + 1];

            resultSetSumBasedOnContentTypes += (number | 0);
            contentTypeCountNumber[typeId] = number;

            i++;
        }
    }

    const totalCountFieldName = Utilities.feature.get(Utilities.feature.consts.useCoveoSearch) === true ?
        'totalCount' :
        'response.numFound';

    let resultSetSize = Utilities.object.getDeepValue(payload, totalCountFieldName) || resultSetSumBasedOnContentTypes || 0;
    resultSetSize = Math.min(resultSetSize, MAX_COVEO_RETURN_SET);
    contentTypeCountNumber[COLLECTIONS.UNIFIED] = resultSetSize;

    Object.values(COLLECTIONS).forEach((typeId) => {
        if (!contentTypeCountNumber.hasOwnProperty(typeId)) {
            contentTypeCountNumber[typeId] = 0;
        }
    });

    return {
        resultSetSize: resultSetSize,
        collectionSizes: contentTypeCountNumber,
    }
}



function addToHierarchy(hierarchy, toAddStr, count, selectedFacets) {
    let currLevel = hierarchy;
    let i = 0;
    const toAdd = toAddStr.split('|');

    if (ENABLED_FACETS.indexOf(toAdd[0]) == -1) {
        return hierarchy;
    }

    while (i < toAdd.length) {
        const value = toAdd[i];
        const deepTitle = _.slice(toAdd, 0, i + 1).join('|');

        let nextLevel = _.find(currLevel.children, l => { return l.id == deepTitle; });
        if (!nextLevel) {
            const selected = selectedFacets.indexOf(deepTitle) !== -1;
            nextLevel = {
                id: deepTitle,
                title: value,
                selected: selected,
                children: []
            };
            currLevel.children.push(nextLevel)
        }

        if (i == toAdd.length - 1) {
            nextLevel.count = count;
        }
        currLevel = nextLevel;
        i++;
    }

    return hierarchy;
}





export function extractSearchResults(payload, collectionName) {
    const fieldMappingObj = Utilities.feature.get(Utilities.feature.consts.useCoveoSearch) === true ?
        COVEO_FIELD_MAPPING :
        FIELD_MAPPING;

    const MAPPING = fieldMappingObj[collectionName];

    const resultsFieldName = Utilities.feature.get(Utilities.feature.consts.useCoveoSearch) === true ?
        'results' :
        'response.docs';

    const respDocs = Utilities.object.getDeepValue(payload, resultsFieldName);

    const results = _.map(respDocs, (doc) => {
        const ret = {};

        Object.keys(MAPPING).forEach(sourceKey => {
            const targetKey = MAPPING[sourceKey];
            ret[targetKey] = Utilities.object.getDeepValue(doc, sourceKey);
        })

        ret.published = (ret.published) ? new Date(ret.published) : undefined;

        if (Utilities.feature.get(Utilities.feature.consts.useCoveoSearch) === true) {
            ret.type = ret.type.toLowerCase();
        }

        ret.original = doc;

        return ret;
    });

    return results;
}

export function getEmptyFacetHierarchy() {
    return {
        isRoot: true,
        title: 'Cloudera Search Facets',
        children: []
    };
};


export function extractFacetInformation(payload, selectedFacets) {
    const facetHierarchy = getEmptyFacetHierarchy();

    if (Utilities.feature.get(Utilities.feature.consts.useCoveoSearch) === true) {
        const facetsInfo = Utilities.object.getDeepValue(payload, 'facets');
        const possibleFacets = _.find(facetsInfo, f => f['facetId'] == '@product_component_version');

        if (possibleFacets) {
            possibleFacets['values'].forEach(f => {
                let numberOfResults = f['numberOfResults'];
                numberOfResults = Math.min(numberOfResults, MAX_COVEO_RETURN_SET);

                addToHierarchy(facetHierarchy, f['value'], numberOfResults, selectedFacets);
            });
        }
    } else {
        const facetsInfo = Utilities.object.getDeepValue(payload, 'facet_counts.facet_fields.content_facets_string');
        let N = facetsInfo.length / 2;
        let i = 0;

        while (i < N) {
            const facetCount = facetsInfo[i * 2 + 1];
            const facetTitle = facetsInfo[i * 2];
            addToHierarchy(facetHierarchy, facetTitle, facetCount, selectedFacets);
            i++;
        }
    }

    facetHierarchy.children.sort((a, b) => ENABLED_FACETS.indexOf(a.title) - ENABLED_FACETS.indexOf(b.title));

    return facetHierarchy;
}