function validator(val, n) {
    return new Promise((res) => {

        n = n || 30;
        // var length = val.trim().length;
        var length = (new TextEncoder().encode(val)).length;

        res({
            result: !val || length <= n,
            state: { details: {} }
        });
    });
}

export default {
    validator: validator,
    validationErrorMessage: 'Field should be at max {{0}} chars.'
}