import EventModelBase from "../eventModelBase";

export default class HelpfulResourcesUpdate extends EventModelBase {

    constructor(application, feature, resourcePayload) {
        super('helpfulResourcesUpdate');

        this.page = {
            ...this.page,
        }

        this.attributes = {
            ...this.attributes,
            application: application,
            feature: feature,

            searchPayload : resourcePayload
        };
    }



}