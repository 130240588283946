import Api from '../../api/api'

function validator(val) {
    return new Promise(async(res) => {
        const payload = {
            "data": val.trim()
        }
        let validity = false;

        try {
            const response = await Api.callEndpoint('SKYNET_EMAIL_CHECKER_URL', { payload });
            validity = true;
        } catch (e) {
            validity = false;
        }

        res({
            result: validity,
            state: {}
        });


    });
}



export default {
    validator: validator,
    validationErrorMessage: 'This email is not available.',
    validationErrorHint: 'This email is not available. Please select a different email address, <a class=\"suds-link inline-link\" href=\"/content/support/sso/en-us.html\">sign in</a> or <a class=\"suds-link inline-link\" href=\"/content/support/sso/en-us/forgotten-password.html\">reset your password</a>.'
}