import EventModelBase from "../eventModelBase";

export default class NotEntitledForCases extends EventModelBase {
    constructor(application, feature, accountId) {
        super('notEntitledForCases');
        this.page = {
            ...this.page,
        }
        this.attributes = {
            ...this.attributes,
            application: application,
            feature: feature,

            accountId   : accountId
        };
    }
}