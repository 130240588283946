import CASE_COMMENT_FIELD_MAPPINGS from '../../config/caseComment.fields.mapping.json';
import CASE_COMMENT_PREFIXES from '../../config/caseCommentPrefixes.json';

import moment from 'moment-timezone';

export const CaseCommentPrefixes = {
    Closing: CASE_COMMENT_PREFIXES.Closing,
    Reopening: CASE_COMMENT_PREFIXES.Reopening
}

export class CaseComment {

    constructor(caseCommentData) {
        const inputCaseCommentData = caseCommentData || {};
        const DATE_TIME_FORMAT = 'MMMM Do, YYYY - hh:mm A z';

        Object.keys(CASE_COMMENT_FIELD_MAPPINGS).forEach(fieldName => {
            if (inputCaseCommentData.hasOwnProperty(CASE_COMMENT_FIELD_MAPPINGS[fieldName])) {
                const val = inputCaseCommentData[CASE_COMMENT_FIELD_MAPPINGS[fieldName]];

                if (val == 'null') {
                    this[fieldName] = null;
                } else {
                    this[fieldName] = val;
                }
            }
        });

        if (!this.ContactFirstName) {
            this.ContactFirstName = '';
        }

        if (!this.ContactLastName) {
            this.ContactLastName = '';
        }

        this.Author = this.ContactFirstName + ' ' + this.ContactLastName;

        if (this.CommentBody && this.CommentBody.startsWith(CASE_COMMENT_PREFIXES.Closing)) {
            this.IsClosing = true;
            this.ClosingCommentBodyWithoutPrefix = this.CommentBody.replace(CASE_COMMENT_PREFIXES.Closing, '').trim();
        }

        if (this.CommentBody && this.CommentBody.startsWith(CASE_COMMENT_PREFIXES.Reopening)) {
            this.IsReopening = true;
            this.ReopeningCommentBodyWithoutPrefix = this.CommentBody.replace(CASE_COMMENT_PREFIXES.Reopening, '').trim();
        }

        if (this.LastModifiedDate) {
            this.LastModifiedDateFormatted = moment(this.LastModifiedDate).tz(moment.tz.guess()).format(DATE_TIME_FORMAT);
        }
    }
}