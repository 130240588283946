import CASE_FIELD_MAPPINGS from '../../config/case.fields.mapping.json'
import CASE_SEVERITY_MAPPINGS from '../../config/caseSeverity.mapping.json'
import CASE_RELATION_MAPPINGS from '../../config/caseRelation.mapping.json'
import CONSTANTS from '../../config/constants.json';

import moment from 'moment-timezone';

export class Case {

    constructor(caseData) {
        const inputCaseData = caseData || {};
        const DATE_TIME_FORMAT = 'MMMM Do, YYYY - hh:mm A z';

        Object.keys(CASE_FIELD_MAPPINGS).forEach(fieldName => {
            if (inputCaseData.hasOwnProperty(CASE_FIELD_MAPPINGS[fieldName])) {
                const val = inputCaseData[CASE_FIELD_MAPPINGS[fieldName]];

                if (val == 'null') {
                    this[fieldName] = null;
                } else {
                    this[fieldName] = val;
                }
            }
        });

        if (this.Owner) {
            this.OwnerName = this.Owner.Name;
        }

        if (this.Parent) {
            this.ParentCaseNumber = this.Parent.CaseNumber;
        }

        if (this.Contact) {
            this.ContactName = this.Contact.Name;
            this.ContactEmail = this.Contact.Email;
            this.ContactPhone = this.Contact.Phone;
        }

        if (this.Account) {
            this.AccountName = this.Account.Name;
        }

        if (this.Asset) {
            this.AssetId = this.Asset.Id;
            this.AssetName = this.Asset.Name;
            this.AssetType = this.Asset.Asset_Type__c;
        }

        if (this.RequestType) {
            this.IsUpgradeType = this.RequestType === CONSTANTS.case.type.upgrade;
        }

        if (this.SubRequestType === 'Validation') {
            this.Type = 'Validation';
            this.ValidationCase = true;
        }

        if (CASE_SEVERITY_MAPPINGS.hasOwnProperty(this.Priority)) {
            this.Priority = CASE_SEVERITY_MAPPINGS[this.Priority];
        }

        if (CASE_RELATION_MAPPINGS.hasOwnProperty(this.CaseRelation)) {
            this.CaseRelation = CASE_RELATION_MAPPINGS[this.CaseRelation];
        }

        if (this.LastModifiedDate) {
            this.LastModifiedDateFormatted = moment(this.LastModifiedDate).tz(moment.tz.guess()).format(DATE_TIME_FORMAT);
        }

        if (this.LastModifiedDateFormatted && this.LastModifiedBy) {
            this.LastModifiedDateWithName = this.LastModifiedDateFormatted + " by " + this.LastModifiedBy.Name;
        }

        if (this.CreatedDate) {
            this.CreatedDateFormatted = moment(this.CreatedDate).tz(moment.tz.guess()).format(DATE_TIME_FORMAT);
        }

        if (this.CreatedDateFormatted && this.CreatedBy) {
            this.CreatedDateWithName = this.CreatedDateFormatted + " by " + this.CreatedBy.Name;
        }

        if (this.ClosedDate) {
            this.ClosedDateFormatted = moment(this.ClosedDate).tz(moment.tz.guess()).format(DATE_TIME_FORMAT);
        }
    }
}