function validator(val, n) {
    return new Promise((res) => {
        n = n || 3;
        res({
            result: !val || val.trim().length >= n,
            state: { details: {} }
        });
    });
}

export default {
    validator: validator,
    validationErrorMessage: 'Field should be at least {{0}} chars.'
}