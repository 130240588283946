import Skynet from '../../skynet'
import Api from '../../api/api'

function validator(val) {

    return new Promise(async(resolve) => {

        const payload = {
            "nickname": val.trim()
        }
        let validity = false;

        try {
            const result = await Api.callEndpoint("SKYNET_NICKNAME_CHECKER_URL", { payload })
            validity = validity || result.data;
        } catch (e) {
            validity = false;
        } finally {
            resolve({
                result: validity,
                state: { details: {} }
            });
        }

    });
}

export default {
    validator: validator,
    validationErrorMessage: 'This nickname is not available.',
    validationErrorHint: 'Nickname has been used, please choose another one.'
}