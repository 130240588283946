import CASE_TEAM_MEMBER_MAPPINGS from '../../config/caseTeamMember.fields.mapping.json'

export class CaseTeamMember {

    constructor(caseTeamMemberData) {
        const inputCaseTeamMemberData = caseTeamMemberData || {};

        Object.keys(CASE_TEAM_MEMBER_MAPPINGS).forEach(fieldName => {
            if (inputCaseTeamMemberData.hasOwnProperty(CASE_TEAM_MEMBER_MAPPINGS[fieldName])) {
                const val = inputCaseTeamMemberData[CASE_TEAM_MEMBER_MAPPINGS[fieldName]];

                if (val == 'null') {
                    this[fieldName] = null;
                } else {
                    this[fieldName] = val;
                }
            }
        });
    }
}