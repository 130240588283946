const symbolRegex = /[!@#$%^&*_|~=:;`'"<>?,.\\\/\{\}\[\]]/;

function validator(val) {
    return new Promise((res) => {
        const state = {
            details: {}
        }

        const result = !symbolRegex.test(val);
        state.details.noSymbol = result;

        res({
            result: result,
            state: state
        });
    });
}

export default {
    validator: validator,
    validationErrorMessage: 'Field can not contain special characters except ()+- and space.'
}