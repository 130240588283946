import Configs from './configs'
import Utilities from './utilities'
import Api from './api/api'
import EDL from './edl/edl';
import Environment from './environment'


function endLoginProcess(redirectTo) {
    const logoutAt = new Date((new Date).getTime() + 10 * 60 * 60 * 1000);
    localStorage.removeItem('auto-logout');
    Utilities.cookie.createForDays('auto-logout', logoutAt.toISOString(), 1);
    window.location = redirectTo;
}

function startLoginProcess(credentials) {
    return new Promise(async(res, rej) => {
        try {
            const loginResult = await Api.callEndpoint('NEW_SKYNET_LOGIN', { payload: credentials, doNotExtractPayload: true });
            EDL.pushEvent(new Cloudera.EDL.models.LoginSuccessEvent(EDL.applications.sso.name, EDL.applications.sso.features.login, loginResult.userProfile, loginResult.redirectTo));
            Utilities.cookie.setAccessToken(loginResult['token']);
            Utilities.cookie.setRefreshToken(loginResult['refreshToken']);
            Utilities.cookie.setUserProfile(Utilities.jwt.decode(loginResult['token']));

            res(loginResult);
        } catch (e) {
            EDL.pushEvent(new Cloudera.EDL.models.LoginErrorEvent(EDL.applications.sso.name, EDL.applications.sso.features.login, (e || {}).clientErrorMessage, (e || {}).skynetErrorCode, (e || {}).clientErrorCode));
            rej(e);
        }
    });
};

function logout() {
    return new Promise((resolve, reject) => {
        Cloudera.Modal.loading('Logging out ...');
        Utilities.cookie.removeLoginRelatedCookies('auto-logout');

        setTimeout(()=> {
            window.location = '/';
            resolve();
        }, 500);
    });
}

function isClouderian(email) {
    const clouderianEmailRegex = /@cloudera.com/;
    const userEmail = email || window.Cloudera.Analytics.RequestDescriptor.actor.email;
    return clouderianEmailRegex.test(userEmail);
}

/////////////////////////////////////////////
// EXPORTS
/////////////////////////////////////////////
export default {
    logout: logout,
    user: {
        isClouderian: isClouderian
    },
    startLoginProcess,
    endLoginProcess
}