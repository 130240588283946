import EventModelBase from "./eventModelBase";

export default class ButtonClickedModel extends EventModelBase {

    constructor(buttonName) {
        super('buttonClicked');

        this.page = {
            ...this.page,
        }

        this.attributes = {
            ...this.attributes,

            btnName: buttonName
        };
    }




}