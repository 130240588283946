import EventModelBase from "../eventModelBase";
import _ from 'lodash';
export default class RegistrationStartedEvent extends EventModelBase {

    constructor(application, feature) {
        super('registrationStartedEvent');

        this.page = {
            ...this.page,
        }

        this.attributes = {
            ...this.attributes,
            application: application,
            feature: feature
        };
    }


}