import BM_CREDIT_BALANCE_FIELD_MAPPINGS from '../../config/bmCreditBalance.fields.mapping.json';
import BM_CREDIT_BALANCE_DETAILED_ITEM_FIELD_MAPPINGS from '../../config/bmCreditBalanceDetailedItem.fields.mapping.json';
import BM_QUOTE_STATUS_MAPPINGS from '../../config/bmQuoteStatus.mapping.json'

import _ from 'lodash';
import Utilities from '../utilities';

export class BmCreditBalance {

    constructor(creditBalanceData) {
        const inputCreditBalanceData = creditBalanceData || {};

        Object.keys(BM_CREDIT_BALANCE_FIELD_MAPPINGS).forEach(fieldName => {
            if (inputCreditBalanceData.hasOwnProperty(BM_CREDIT_BALANCE_FIELD_MAPPINGS[fieldName])) {
                const val = inputCreditBalanceData[BM_CREDIT_BALANCE_FIELD_MAPPINGS[fieldName]];

                if (val == 'null' || val == null) {
                    this[fieldName] = '-';
                } else {
                    this[fieldName] = val;
                }
            }
        });

        if (Number(this.PurchasedBalance) === 0) {
            this.Percentage = 0;
        }
        else {
            this.Percentage = ((Number(this.RemainingBalance) / Number(this.PurchasedBalance) * 100)).toFixed();
        }

        this.PurchasedBalance = Utilities.formatting.numberWithCommas(this.PurchasedBalance);
        this.RemainingBalance = Utilities.formatting.numberWithCommas(this.RemainingBalance);

        if (this.Details) {
            this.Details = _.map(this.Details, item => {
                const inputCreditBalanceDetailedItemData = item || {};
                const detailedItem = {};

                Object.keys(BM_CREDIT_BALANCE_DETAILED_ITEM_FIELD_MAPPINGS).forEach(fieldName => {
                    if (inputCreditBalanceDetailedItemData.hasOwnProperty(BM_CREDIT_BALANCE_DETAILED_ITEM_FIELD_MAPPINGS[fieldName])) {
                        const val = inputCreditBalanceDetailedItemData[BM_CREDIT_BALANCE_DETAILED_ITEM_FIELD_MAPPINGS[fieldName]];

                        if (val == 'null' || val == null) {
                            detailedItem[fieldName] = '-';
                        } else {
                            detailedItem[fieldName] = val;
                        }
                    }
                });

                if (BM_QUOTE_STATUS_MAPPINGS.hasOwnProperty(detailedItem.Status)) {
                    detailedItem.Status = BM_QUOTE_STATUS_MAPPINGS[detailedItem.Status];
                }

                detailedItem.CreditsPurchased = Utilities.formatting.numberWithCommas(detailedItem.CreditsPurchased);

                return detailedItem;
            });
        }
    }
}