function validator(val) {
    return new Promise((res) => {
        res({
            result: typeof val !== 'undefined' && val != null && ('' + val).trim().length > 0,
            state: { details: {} }
        });
    });
}

export default {
    validator: validator,
    validationErrorMessage: 'Please fill this field.',
    validationErrorHint: 'This field can not be blank.'
}