import ACCOUNT_TEAM_MEMBER_FIELD_MAPPINGS from '../../config/accountTeamMember.fields.mapping.json';
import ACCOUNT_TEAM_MEMBER_USER_ITEM_FIELD_MAPPINGS from '../../config/accountTeamMemberUserItem.fields.mapping.json';

import _ from 'lodash';
import Utilities from '../utilities';

export class AccountTeamMember {
    constructor(accountTeamMemberData) {
        const inputAccountTeamMemberData = accountTeamMemberData || {};

        Object.keys(ACCOUNT_TEAM_MEMBER_FIELD_MAPPINGS).forEach(fieldName => {
            if (inputAccountTeamMemberData.hasOwnProperty(ACCOUNT_TEAM_MEMBER_FIELD_MAPPINGS[fieldName])) {
                const val = inputAccountTeamMemberData[ACCOUNT_TEAM_MEMBER_FIELD_MAPPINGS[fieldName]];

                if (val == 'null' || val == null) {
                    this[fieldName] = '-';
                } else {
                    this[fieldName] = val;
                }
            }
        });

        /*if (this.User) {
            this.User = _.map(this.User, user => {
                const inputAccountTeamMemberUserItemData = user || {};
                const detailedItem = {};

                Object.keys(ACCOUNT_TEAM_MEMBER_USER_ITEM_FIELD_MAPPINGS).forEach(fieldName => {
                    if (inputAccountTeamMemberUserItemData.hasOwnProperty(ACCOUNT_TEAM_MEMBER_USER_ITEM_FIELD_MAPPINGS[fieldName])) {
                        const val = inputAccountTeamMemberUserItemData[ACCOUNT_TEAM_MEMBER_USER_ITEM_FIELD_MAPPINGS[fieldName]];

                        if (val == 'null' || val == null) {
                            detailedItem[fieldName] = '-';
                        } else {
                            detailedItem[fieldName] = val;
                        }
                    }
                });

                return detailedItem;
            });
        }*/
    }
}