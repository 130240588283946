function validator(val) {
    return new Promise((res) => {
        const state = {
            details: {}
        }

        const result = !(val == 'user' || val == 'unknown');
        state.details.noDefaultNaming = result;

        res({
            result: result,
            state: state
        });
    });
}

export default {
    validator: validator,
    validationErrorMessage: 'Please enter a valid first and last name.'
}