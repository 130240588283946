import MDF_WITHDRAWAL_DATA_FIELD_MAPPINGS from '../../config/mdfWithdrawalData.fields.mapping.json';
import MDF_WITHDRAWAL_STATUS_MAPPINGS from '../../config/mdfWithdrawalStatus.mapping.json';
import MDF_WITHDRAWAL_APPROVED_AMOUNT_MAPPINGS from '../../config/mdfWithdrawalApprovedAmount.mapping.json';

import moment from 'moment-timezone';

export class MdfWithdrawalData {

    constructor(mdfWithdrawalData) {
        const inputMdfWithdrawalData = mdfWithdrawalData || {};

        Object.keys(MDF_WITHDRAWAL_DATA_FIELD_MAPPINGS).forEach(fieldName => {
            if (inputMdfWithdrawalData.hasOwnProperty(MDF_WITHDRAWAL_DATA_FIELD_MAPPINGS[fieldName])) {
                const val = inputMdfWithdrawalData[MDF_WITHDRAWAL_DATA_FIELD_MAPPINGS[fieldName]];

                if (val == 'null' || val == null) {
                    this[fieldName] = '-';
                } else {
                    this[fieldName] = val;
                }
            }
        });

        if (MDF_WITHDRAWAL_STATUS_MAPPINGS.hasOwnProperty(this.Status)) {
            this.Status = MDF_WITHDRAWAL_STATUS_MAPPINGS[this.Status];
        }

        if (MDF_WITHDRAWAL_APPROVED_AMOUNT_MAPPINGS.hasOwnProperty(this.Status)) {
            this.ApprovedAmount = MDF_WITHDRAWAL_APPROVED_AMOUNT_MAPPINGS[this.Status];
        }

        if (this.StartDate) {
            this.StartDateFormatted = moment(this.StartDate).format('YYYY-MM-DD');
        }

        if (this.EndDate) {
            this.EndDateFormatted = moment(this.EndDate).format('YYYY-MM-DD');
        }

        if (this.RequestDate) {
            this.RequestDateFormatted = moment(this.RequestDate).format('YYYY-MM-DD');
        }
    }
}