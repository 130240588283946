function validator() {
    return new Promise((res) => {
        res({
            result: true,
            state: { details: {} }
        });
    });
}

export default {
    validator: validator,
    validationErrorMessage: '',
    validationErrorHint: ''
}