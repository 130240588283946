import endpoints from '../config/newEndpoints.json'
import errors from '../config/errors.json'
import uiErrors from '../config/uiErrors.json'
import assetStatusIcons from '../config/assetStatusIcon.mapping.json'
import caseStatusIcons from '../config/caseStatusIcon.mapping.json'
import caseSeverityIcons from '../config/caseSeverityIcon.mapping.json'
import caseMetadataIcon from '../config/caseMetadataIcon.mapping.json'
import mdfWithdrawalStatusIcons from '../config/mdfWithdrawalStatusIcon.mapping.json'
import assetValidationSeverities from '../config/assetValidationSeverity.mapping.json'
import adminUserSharingTypes from '../config/adminUserSharingType.mapping.json'

export default {
    EndPoints: {
        ...endpoints
    },
    Errors: {
        ...errors
    },
    UIErrors: {
        ...uiErrors
    },
    Mappings: {
        AssetStatus: {
            ...assetStatusIcons
        },
        CaseStatus: {
            ...caseStatusIcons
        },
        CaseSeverity: {
            ...caseSeverityIcons
        },
        CaseMetadata: {
            ...caseMetadataIcon
        },
        MdfWithdrawalStatus: {
            ...mdfWithdrawalStatusIcons
        },
        AssetValidationSeverity: {
            ...assetValidationSeverities
        },
        AdminUserSharingType: {
            ...adminUserSharingTypes
        }
    }
}