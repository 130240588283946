import EventModelBase from "../eventModelBase";

export default class JourneyAdvisorAccountDetailsLoadingErrorModel extends EventModelBase {

    constructor(accountId, skynetError, clientError) {
        super('journeyAdvisorAccountDetailsLoadingError');

        this.page = {
            ...this.page,

        }

        this.attributes = {
            ...this.attributes,

            accountId: accountId,

            skynetErrorCode: skynetError,
            clientSideErrorCode: clientError
        };
    }




}