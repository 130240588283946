import Utilities from '../utilities'


const SAML_REQUEST_COOKIE_NAME = 'saml_request_path';
const SSO_URL_COOKIE_NAME = 'sso-url';


function redirectThroughRedirectPage(target) {
    if (target) {
        Utilities.cookie.createForDays(SAML_REQUEST_COOKIE_NAME, target, 1);
    }
    window.location = '/content/support/sso/en-us/redirect.html';
}

function redirectThroughRedirectPageToExternal(target) {
    if (target) {
        Utilities.cookie.createForDays(SSO_URL_COOKIE_NAME, target, 1);
    }
    window.location = '/content/support/sso/en-us/redirect.html';
}

export default {
    redirection: {
        toInternal: redirectThroughRedirectPage,
        toExternal: redirectThroughRedirectPageToExternal
    }
}