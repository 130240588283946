import EventModelBase from "../eventModelBase";
import _ from 'lodash';

export default class LoginErrorEvent extends EventModelBase {

    constructor(application, feature, message, skynetErrorCode, clientErrorCode='') {
        super('loginErrorEvent');

        this.page = {
            ...this.page,
        }

        this.attributes = {
            ...this.attributes,
            skynetErrorCode: skynetErrorCode,
            clientSideErrorCode: clientErrorCode,
            application: application,
            feature: feature
        };

        this.sso = {
            loginErrorMessage: message
        };
    }


}