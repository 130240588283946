import _ from 'lodash';
import Utilities from '../utilities';

const ORACEL_PUNCH_THROUGH_URL_ARGUMENT_NAME = 'security-token';
const ORACLE_PUNCH_THROUGH_TOKEN_KEY_NAME = 'security-token';
const KB_REDIRECTION_TARGET_PLACEHOLDER = '{{kbRedirectionTarget}}';

const PUNCH_THROUGH_SKYNET_HTTP_ERRORCODES = [400, 403];

let PUNCH_THROUGH_FALLBACK_URL = undefined;

function getTokenFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(ORACEL_PUNCH_THROUGH_URL_ARGUMENT_NAME);
}

function removeTokenFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete(ORACEL_PUNCH_THROUGH_URL_ARGUMENT_NAME);

    if (window.history.replaceState) {
        window.history.replaceState(undefined, window.document.title, window.location.pathname + '?' + urlParams.toString());
    }
}


function enablePunchThrough(punchThroughFallbackUrl) {
    PUNCH_THROUGH_FALLBACK_URL = punchThroughFallbackUrl;

    const token = getTokenFromUrl();

    if (token) {
        Utilities.storage.setItem(ORACLE_PUNCH_THROUGH_TOKEN_KEY_NAME, token);
        removeTokenFromUrl();
        return true;
    }

    return false;
}

function getPunchThroughToken() {
    return Utilities.storage.getItem(ORACLE_PUNCH_THROUGH_TOKEN_KEY_NAME);
}

function checkKbRetrivalError(endpointError) {
    if (PUNCH_THROUGH_SKYNET_HTTP_ERRORCODES.indexOf(endpointError.httpCode) > -1) {
        Utilities.storage.removeItem(ORACLE_PUNCH_THROUGH_TOKEN_KEY_NAME);
        redirectToPunchThroughFallbackUrl();
        return true;
    }
}

function redirectToPunchThroughFallbackUrl() {
    const currUrl = window.location.href;

    if (PUNCH_THROUGH_FALLBACK_URL) {
        const redirectTo = PUNCH_THROUGH_FALLBACK_URL.replace(KB_REDIRECTION_TARGET_PLACEHOLDER, encodeURIComponent(currUrl));
        window.location = redirectTo;
    }
}



export default {
    enablePunchThrough,
    getPunchThroughToken,
    checkKbRetrivalError
}