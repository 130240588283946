import Utilities from '../../utilities';
import EventModelBase from "./eventModelBase";
import { SERVER_SIDE_ANALYTICS_DATA } from './eventModelBase';

export default class PageViewEventModel extends EventModelBase {

    constructor() {
        super('pageView');


        const path = window.location.pathname;
        const cleanedPath = path.replace(".html", "");
        const pathParts = cleanedPath.split('/') || [];


        this.page = {
            ...this.page,

            pageModifiedDate: Utilities.object.getDeepValue(SERVER_SIDE_ANALYTICS_DATA, 'page.lastModified'),
            pagePublishDate: Utilities.object.getDeepValue(SERVER_SIDE_ANALYTICS_DATA, 'page.lastPublished'),
            pageName: this.generatePageName(),
            pageTitle: document.title,
            pageType: '',

            siteSection: pathParts[1] || '',
            subSection1: pathParts[2] || '',
            subSection2: pathParts[3] || '',
        }

        this.attributes = {
            ...this.attributes,

            sfdcAccountName: Utilities.object.getDeepValue(SERVER_SIDE_ANALYTICS_DATA, 'actor.accountName'),
            sfdcAccountType: Utilities.object.getDeepValue(SERVER_SIDE_ANALYTICS_DATA, 'actor.accountType'),
            jobFunction: Utilities.object.getDeepValue(SERVER_SIDE_ANALYTICS_DATA, 'actor.jobFunction'),
            jobRole: Utilities.object.getDeepValue(SERVER_SIDE_ANALYTICS_DATA, 'actor.jobRole'),
            loginStatus: (!!Utilities.object.getDeepValue(SERVER_SIDE_ANALYTICS_DATA, 'actor.oktaId')) ? "logged-in" : 'anonymous',
            portalRole: Utilities.object.getDeepValue(SERVER_SIDE_ANALYTICS_DATA, 'actor.portalRoles'),

            application: '',
            feature: ''
        };
    }




}