// import services from './services.js'
import ajax from './ajax.js'
import axios from 'axios'

import Skynet from '../skynet'
import { Channels, AJAX_ATTEMPT, AJAX_FAILURE, AJAX_SUCCESS } from '../channels'

import ENDPOINT_CONFIGS from '../../config/newEndpoints.json'
import { getRuntimeEnvironment, getFullSkynetUrl, isHostedOn3rdPartySystem, getCurrentEnv } from '../environment'
const Endpoints = {...ENDPOINT_CONFIGS };
const EndpointHistory = {};

const SKYNET_PROTECTED_PROXY = "/bin/services/support/api/protected.";
const SKYNET_PUBLIC_PROXY = "/bin/services/support/api/public.";

/* develblock:start */
const urlExtendPrefix = 'http://localhost:4503';
/* develblock:end */

export function checkCallOptions(endpoint, callOptions) {
    return true;
}

export function checkEndpointDefinition(endpoint) {
    if (!endpoint || typeof(endpoint) !== 'object') {
        return false;
    }
    if (!endpoint.path) {
        return false;
    }
    if (!endpoint.target) {
        return false;
    }
    if (!endpoint.method) {
        return false;
    }
    if (!endpoint.type) {
        if (endpoint.target == 'skynet') {
            return false;
        }
    } else {
        if (endpoint.target != 'skynet') {
            return false;
        }
    }

    return true;
}

export function getEndpointPath(path, urlParams, queryParams) {
    let ret = path;
    for (const paramName in urlParams) {
        const placeholder = '{{' + paramName + '}}';
        const val = urlParams[paramName];
        ret = ret.replace(placeholder, val);
    }

    if (queryParams) {
        const toAppend = [];
        Object.keys(queryParams).forEach(key => {
            if (typeof(queryParams[key]) !== 'undefined') {
                toAppend.push(key + "=" + (queryParams[key]));
            }
        });

        let appendString = toAppend.join('&');
        if (toAppend.length > 0) {
            if (ret.indexOf('?') == -1) {
                appendString = '?' + appendString;
            }
            ret = ret + appendString;
        }
    }
    return ret;
}

export function constructUrl(endpoint, urlParams, queryParams) {
    let endpointPath = getEndpointPath(endpoint.path, urlParams, queryParams);

    var extendToAbsolute = false;

    // todo: check if valid 3rd party sytem
    const domain = document.location.hostname;
    let env = getCurrentEnv();
    if (isHostedOn3rdPartySystem(domain)) {
        env = getRuntimeEnvironment(domain);
        extendToAbsolute = true;
    }

    endpointPath = getFullSkynetUrl(env, endpointPath);

    return endpointPath;
}

function logEndpointUsage(endpointName, callOptions) {
    EndpointHistory[endpointName] = EndpointHistory[endpointName] || [];
    EndpointHistory[endpointName].push(callOptions);
}

function showEndpointUsage() {
    console.log(EndpointHistory);
}

export async function executeEndpoint(endpointName, callOptions) {
    try {
        callOptions = callOptions || {};
        let { payload, axiosConfig, urlParams, queryParams, doNotExtractPayload, returnAxiosResult } = callOptions;

        logEndpointUsage(endpointName, callOptions);

        const endpoint = Endpoints[endpointName];

        if (!endpoint) {
            throw "No endpoint defined with name '" + endpointName + "'.";
        }
        if (!checkEndpointDefinition(endpoint)) {
            throw "Endpoint definition is not valid for '" + endpointName + "'.";
        }
        if (!checkCallOptions(endpoint, callOptions)) {
            throw "Call options is not valid for '" + endpointName + "'. ";
        }


        const url = constructUrl(endpoint, urlParams, queryParams);
        axiosConfig = axiosConfig || {};

        if (url.indexOf('secure.') > -1) {
            axiosConfig.withCredentials = true;
        }
        axiosConfig.skynetEndpointType = endpoint.type;

        let axiosResult;
        switch (endpoint.method) {
            case 'post':    
                Channels.publishSync(AJAX_ATTEMPT, { endpointName, endpoint, callOptions, url });
                axiosResult = await axios.post(url, payload, axiosConfig);
                break;
            case 'put':
                Channels.publishSync(AJAX_ATTEMPT, { endpointName, endpoint, callOptions, url });
                axiosResult = await axios.put(url, payload, axiosConfig);
                break;
            case 'get':
                Channels.publishSync(AJAX_ATTEMPT, { endpointName, endpoint, callOptions, url });
                axiosResult = await axios.get(url, axiosConfig);
                break;
            case 'delete':
                Channels.publishSync(AJAX_ATTEMPT, { endpointName, endpoint, callOptions, url });
                axiosConfig.data = payload;
                axiosResult = await axios.delete(url, axiosConfig);
                break;
            default:
                throw 'Invalid endpoint method';
        }

        // TODO: check skynet response format
        let result = axiosResult.data;
        Channels.publishSync(AJAX_SUCCESS, { response: axiosResult.data, endpointName, endpoint, callOptions });

        if (!doNotExtractPayload) {
            result = axiosResult.data.payload;
        }

        if (returnAxiosResult) {
            result = axiosResult;
        }

        return result;
    } catch (e) {
        e = e || {};
        const errorResponse = e.response;
        const uiError = Skynet.handlers.createSkynetUIError(e);
        Channels.publishSync(AJAX_FAILURE, { endpointName, callOptions, uiError, originalError: e, response: errorResponse });
        throw uiError;
    }
}

export function registerEndpoint(name, { path, target, type, method }) {
    Endpoints[name] = { path, target, type, method };
}

export default {
    // AEM: services.aem,
    // Skynet: services.skynet,
    Ajax: ajax,

    callEndpoint: executeEndpoint,
    registerEndpoint: registerEndpoint,

    showEndpointUsage,

    checkEndpointDefinition
}