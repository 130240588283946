import ASSET_FIELD_MAPPINGS from '../../config/asset.fields.mapping.json'
import INLINE_ASSET_FIELD_MAPPINGS from '../../config/inline_asset.fields.mapping.json'

import _ from "lodash";
import moment from 'moment-timezone';

export class Asset {

    constructor(assetData) {
        const inputAssetData = assetData || {};

        Object.keys(ASSET_FIELD_MAPPINGS).forEach(fieldName => {
            if (inputAssetData.hasOwnProperty(ASSET_FIELD_MAPPINGS[fieldName])) {
                const val = inputAssetData[ASSET_FIELD_MAPPINGS[fieldName]];

                if (val == 'null' || val === null) {
                    this[fieldName] = null;
                } else {
                    this[fieldName] = val;
                }
            }
        });

        this.IsLastBundleReceived = this.LastBundleReceived !== null;

        if (typeof this.ValidationCount !== 'undefined') {
            this.ValidationCount = this.IsLastBundleReceived === true ? Number(this.ValidationCount).toString() : '-';
        }

        if (typeof this.CriticalErrorCount !== 'undefined') {
            this.HasCriticalOrErrorValidation = Number(this.CriticalErrorCount) > 0;
        }

        if (typeof this.NumberOfNodes !== 'undefined') {
            this.NumberOfNodes = Number(this.NumberOfNodes).toString();
        }

        if (typeof this.NumberOfDataNodes !== 'undefined') {
            this.NumberOfDataNodes = Number(this.NumberOfDataNodes).toString();
        }

        if (typeof this.NumberOfNameNodes !== 'undefined') {
            this.NumberOfNameNodes = Number(this.NumberOfNameNodes).toString();
        }

        if (typeof this.NumberOfDataFlowHosts !== 'undefined') {
            this.NumberOfDataFlowHosts = Number(this.NumberOfDataFlowHosts).toString();
        }

        if (typeof this.NumberOfNifiNodes !== 'undefined') {
            this.NumberOfNifiNodes = Number(this.NumberOfNifiNodes).toString();
        }

        if (this.ClouderaManaged) {
            this.ClouderaManaged = (this.ClouderaManaged == 'true' || this.ClouderaManaged == 'True') || false;
        }

        if (this.LastModifiedDate) {
            this.LastModifiedDateFormatted = moment(this.LastModifiedDate).tz(moment.tz.guess()).format('MMMM Do, YYYY - hh:mm A z');
        }

        this.LastBundleReceivedFormatted = this.LastBundleReceived != null ? moment(this.LastBundleReceived).tz(moment.tz.guess()).format('MMM Do, YYYY - hh:mm A') : '-';

        this.isNewLocal = false;
        this.isEos = false;
    }

    static convertToSfdcProperties(asset) {
        const sfdcAsset = {};

        let clonedDeal = _.cloneDeep(asset);

        Object.keys(clonedDeal).forEach(uiFieldName => {
            const prop = _.find(ASSET_FIELD_MAPPINGS, (sfdcPropName, uiPropName) => {
                return uiPropName == uiFieldName;
            });

            sfdcAsset[prop] = clonedDeal[uiFieldName];
        });

        return sfdcAsset;
    }

    static convertToInlineSfdcProperties(asset) {
        const sfdcAsset = {};

        let clonedDeal = _.cloneDeep(asset);

        Object.keys(clonedDeal).forEach(uiFieldName => {
            const prop = _.find(INLINE_ASSET_FIELD_MAPPINGS, (sfdcPropName, uiPropName) => {
                return uiPropName == uiFieldName;
            });

            if (prop) {
                sfdcAsset[prop] = clonedDeal[uiFieldName];
            }
        });

        return sfdcAsset;
    }

    static enrichWithEosData(asset, versions, eosInfo) {
        const { cdhVersions, cmVersions, hdpVersions, dataFlowVersions } = versions;



        let isEos = false;

        switch (asset.RecordTypeName) {
            case 'CDH':
                const cdhEos = _.some(cdhVersions, cdhVersion => (asset.CDHVersion || '').startsWith(cdhVersion.name) && cdhVersion.eos);
                const cmEos = _.some(cmVersions, cmVersion => (asset.CMVersion || '').startsWith(cmVersion.name) && cmVersion.eos);

                isEos = (cdhEos || cmEos);
                break;

            case 'HDP':
                const hdpEos = _.some(hdpVersions, hdpVersion => (asset.HDPVersion || '').startsWith(hdpVersion.name) && hdpVersion.eos);

                isEos = hdpEos;
                break;

            case 'DataFlow':
                const dfEos = _.some(dataFlowVersions, dfVersion => (asset.DataFlowVersion || '').startsWith(dfVersion.name) && dfVersion.eos);

                isEos = dfEos;
                break;

            case 'Key Trustee':
                break;
            case 'CDP':
                // No EOS checks for these types

                
                const relatedEos = (_.find(eosInfo || [], ei => {
                    return ei.product.startsWith("CDP");
                }) || {}).eosVersions || [];
                isEos = relatedEos.indexOf(asset.CRVersion) > -1;

                break;

            default:
                break;
        }

        /* develblock:start */
        // if (isEos) {
        //     console.log(asset.Name + ' is EOS');
        // }
        /* develblock:end */

        asset.isEos = isEos;

        return asset;
    }

}