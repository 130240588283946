export default {
    Asset: {
        Information: 'INFORMATION',
        Encryption: 'ENCRYPTION',
        Capacity: 'CAPACITY',
        Software: 'SOFTWARE'
    },
    Deal: {
        CustomerInformation: 'CUSTOMER',
        DealInformation: 'DEAL',
        ContactInformation: 'CONTACT',
        NextSteps: 'NEXT',
        UseCases: 'USECASES'
    }
}