import ASSET_VALIDATION_FIELD_MAPPINGS from '../../config/assetValidation.fields.mapping.json';

export class AssetValidation {
    constructor(assetValidationData) {
        const inputAssetValidationData = assetValidationData || {};

        Object.keys(ASSET_VALIDATION_FIELD_MAPPINGS).forEach(fieldName => {
            if (inputAssetValidationData.hasOwnProperty(ASSET_VALIDATION_FIELD_MAPPINGS[fieldName])) {
                const val = inputAssetValidationData[ASSET_VALIDATION_FIELD_MAPPINGS[fieldName]];

                if (val == 'null' || val == null) {
                    this[fieldName] = null;
                } else {
                    this[fieldName] = val;
                }
            }
        });

        let hostsCountValue = Number(this.hostsCount);
        this.hostsCount = hostsCountValue > 0 ? hostsCountValue.toString() : '-';

        if (this.hosts) {
            this.hosts = this.hosts.length > 0 ? this.hosts.join(', ') : '-';
        }
    }
}