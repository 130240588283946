import Utilities from '../../utilities';
import EventModelBase from "./eventModelBase";

export default class DownloadInitiatedModel extends EventModelBase {

    constructor(application, feature, formName) {
        super('downloadInitiated');




        this.page = {
            ...this.page,


        }

        this.attributes = {
            ...this.attributes,

            downloadAttempt: true,
            application: application,
            feature: feature,
            formDownloaded: formName
        };
    }




}