export const SAML_REQUEST_COOKIE_NAME = 'saml_request_path';

import _ from 'lodash'
import $ from 'jquery'
import Papa from 'papaparse';
import { jwtDecode } from 'jwt-decode';

const ACTOR_MOCK = {

};
const CurrencyLocales = {
    "USD": "en-US",
    "AUD": "en", //"en-AU"
    "CAD": "en", //"en-CA"
    "EUR": "de", //"de-DE"
    "GBP": "en", //"en-GB"
    "JPY": "ja", //"ja-JP"
    "CNY": "zh"
};

const PAGE_LOADED_CLASS_NAME = "myc-app-loaded";


/////////////////////////////////////////////
// URL UTILITIES
/////////////////////////////////////////////

function getUrlSearchPart(id) {
    const truncatedSearchStr = (location.search || '?').substring(1);
    const requiredPart = _.find(truncatedSearchStr.split('&'), (item) => {
        return item.startsWith(id);
    }) || '=';
    return requiredPart.split('=')[1];
}

function removeSearchArgs() {
    const loc = window.location.href;
    const host = window.location.host;
    const hostRemovedUrl = loc.substr(loc.indexOf(host) + host.length);

    window.history.replaceState(undefined, "", hostRemovedUrl.split("?")[0]);

}

function redirectThroughRedirectPage(target, disableLoadingScreen) {
    if (!disableLoadingScreen) {
        Cloudera.Modal.loading('Redirecting ...');
    }

    // todo: make sure target is saved to the correct cookie which is picked up by the redirect.html
    if (target) {
        createCookieForDays(SAML_REQUEST_COOKIE_NAME, target, 1);
    }

    window.location = '/content/support/sso/en-us/redirect.html';

}

function redirectToTarget(target, disableLoadingScreen, removeSsoCookies) {
    if (!disableLoadingScreen) {
        Cloudera.Modal.loading('Redirecting ...');
    }

    if (removeSsoCookies) {
        removeSsoRelatedCookies();
    }

    window.location = target;
}

function cancelRedirect() {
    Cloudera.Modal.loading('Redirecting ...');

    removeSsoRelatedCookies();
    window.location = '/content/support/sso/en-us/redirect.html';
}

function redirectToOnboarding(stepId, extraParams) {
    let target = '/content/support/sso/en-us/onboarding.html?stepId=' + stepId;

    if (extraParams) {
        Object.keys(extraParams).forEach(key => {
            target += "&" + key + "=" + extraParams[key];
        });
    }

    window.location = target;
}

/////////////////////////////////////////////
// BREAKPOINT UTILITIES
/////////////////////////////////////////////

function getCurrentBreakpoint() {
    const width = window.innerWidth;

    if (width < 768) {
        return 'xs';
    }
    if (width < 992) {
        return 'sm';
    }
    if (width < 1200) {
        return 'md';
    }
    return 'lg';
}

/////////////////////////////////////////////
// OBJECT UTILITIES
/////////////////////////////////////////////

function hasDeepValue(obj, path) {
    if (!obj || !path) {
        return false;
    }
    const parts = path.split('.');
    let i = 0;
    let currLevel = obj;
    while (i < parts.length) {
        currLevel = currLevel[parts[i]];

        if (!currLevel) {
            return false;
        } else {
            ++i;
        }
    }
    return true;
}

function getDeepValue(obj, path) {
    if (!obj || !path) {
        return false;
    }
    const parts = path.split('.');
    let i = 0;
    let currLevel = obj;
    while (i < parts.length) {
        currLevel = currLevel[parts[i]];

        if (typeof currLevel == 'undefined' || currLevel == null) {
            return undefined;
        } else {
            ++i;
        }
    }
    return currLevel;
}

function setDeepValue(obj, path, value) {
    if (!obj || !path) {
        return false;
    }
    const parts = path.split('.');

    if (parts.length < 2) {
        obj[path] = value;
        return true;
    }

    let i = 0;
    let currLevel = obj;
    while (i < parts.length - 1) {
        const nextPropName = parts[i];

        // check if we can go further
        if (!currLevel[nextPropName]) {
            currLevel[nextPropName] = {};
        }

        currLevel = currLevel[nextPropName];

        ++i;
    }
    const lastPropName = parts[i];
    currLevel[lastPropName] = value;
    return true;
}

function traverseHierarchy(hierarchy, itemsSelector, applyFunc) {
    if (typeof applyFunc == 'function') {
        applyFunc(hierarchy);
    }

    const children = getDeepValue(hierarchy, itemsSelector);
    if (children) {
        children.forEach(child => {
            traverseHierarchy(child, itemsSelector, applyFunc);
        });
    }
}

function traverseUpHierarchy(hierarchy, parentSelector, rootSelector, applyFunc) {
    if (typeof applyFunc == 'function') {
        applyFunc(hierarchy);
    }
    const parent = hierarchy[parentSelector];
    const root = hierarchy[rootSelector];
    if (!root) {
        traverseUpHierarchy(parent, parentSelector, rootSelector, applyFunc);
    }
}

function flattenHierarchyBy(hierarchy, itemsSelector, propSelector) {
    if (!hierarchy) {
        return [];
    }

    const flatNodes = [];
    traverseHierarchy(hierarchy, itemsSelector, (node) => {
        const propValue = getDeepValue(node, propSelector);
        if (propValue) {
            flatNodes.push(propValue);
        }
    });

    return flatNodes;
}


/////////////////////////////////////////////
// COMMON REQUESTS
/////////////////////////////////////////////

function fetchSecurityQuestions(userId) {
    return new Promise((resolve, reject) => {


    });
}


/////////////////////////////////////////////
// DOM UTILS
/////////////////////////////////////////////

function scrollToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

function removeHeaderAndFooterTrails() {
    $(document.body).css('padding', '0')
}

const instanceActions = {};

function allInstanceActionsReturned() {
    return _.every(instanceActions, (instanceActionCount) => {
        return instanceActionCount === 0;
    });
}

function setPageLoadedState(pageLoadedValue, checkInstanceActions) {
    if (pageLoadedValue === true) {
        if (checkInstanceActions === true) {
            if (allInstanceActionsReturned() === true) {
                $("html").addClass(PAGE_LOADED_CLASS_NAME);
            }
        } else {
            setTimeout(() => {
                $("html").addClass(PAGE_LOADED_CLASS_NAME);
            }, 50);
        }
    } else {
        $("html").removeClass(PAGE_LOADED_CLASS_NAME);
    }
}

function checkStoreActionsForPageLoadedState(store) {
    const unsubscribe = store.subscribeAction({
        before: (action) => {
            setPageLoadedState(false);

            if (!instanceActions.hasOwnProperty(action.type)) {
                instanceActions[action.type] = 0;
            }

            instanceActions[action.type]++;
        },
        after: (action) => {
            if (instanceActions.hasOwnProperty(action.type)) {
                instanceActions[action.type]--;
            }

            let allReturned = allInstanceActionsReturned();
            if (allReturned) {
                setPageLoadedState(true);
            }
        }
    });

    return unsubscribe;
}

function createPageLoadedCheckerAppExtension(storeActionUnsubscriber) {
    return {
        beforeMount() {
            setPageLoadedState(false);
        },
        mounted() {
            setPageLoadedState(true, true);
        },
        beforeDestroy: function() {
            storeActionUnsubscriber();
        }
    }
}

const domReadyCallbacks = [];
let isDomReady = false;

function registerDomReadyCallback(callbackFn) {
    if (!isDomReady) {
        domReadyCallbacks.push(callbackFn);
    } else {
        console.log('Trying to register DOM Ready event handler after DOM is ready. Executing the handler instead...')
        callbackFn();
    }
}

$(document).ready(function() {
    const dcxaLoadCheck = setInterval(function() {
        if (window.Cloudera) {
            console.log('Executing DOM ready event handlers...')
            domReadyCallbacks.forEach(async function(callbackFn) {
                callbackFn();
            });
            isDomReady = true;
            clearInterval(dcxaLoadCheck);
        }
    }, 200);

});

/////////////////////////////////////////////
// COOKIE UTILS
/////////////////////////////////////////////


function createCookieForDays(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function createCookieForMinutes(name, value, minutes) {
    var expires = "";
    if (minutes) {
        var date = new Date();
        date.setTime(date.getTime() + (minutes * 60 * 1000));
        if (minutes === -1) {
            expires = "; expires=-1";
        } else {
            expires = "; expires=" + date.toUTCString();
        }
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function createCookieForSession(name, value) {
    createCookieForDays(name, value);
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}

function removeCookie(name) {
    createCookieForMinutes(name, '', -1);
}

function removeSsoRelatedCookies() {
    removeCookie('sso-url');
    removeCookie('saml_request_path');
}

const LOGIN_TOKEN_COOKIE_NAME = "login-token";
const REFRESH_TOKEN_COOKIE_NAME = "refresh-token";
const PROFILE_COOKIE_NAME = "userProfile";
const USER_SCOPES = "userScopes";
const ONBOARDING_COOKIE_NAME = "onboarding";

function removeLoginRelatedCookies() {
    removeCookie(LOGIN_TOKEN_COOKIE_NAME);
    removeCookie(REFRESH_TOKEN_COOKIE_NAME);
    removeCookie(PROFILE_COOKIE_NAME);
    removeCookie(ONBOARDING_COOKIE_NAME);
    removeCookie(USER_SCOPES);
}

function setAccessToken(value) {
    createCookieForMinutes(LOGIN_TOKEN_COOKIE_NAME, value, 15)
}

function readAccessToken() {
    return readCookie(LOGIN_TOKEN_COOKIE_NAME);
}

function setRefreshToken(value) {
    createCookieForMinutes(REFRESH_TOKEN_COOKIE_NAME, value, 720)
}

function readRefreshToken() {
    return readCookie(REFRESH_TOKEN_COOKIE_NAME);
}

function setUserProfile(value) {
    if (value) {
        createCookieForMinutes(PROFILE_COOKIE_NAME, JSON.stringify(value), 720)
    }
}

function setUserScopes(value) {
    if (value) {
        createCookieForMinutes(USER_SCOPES, JSON.stringify(value), 1440)
    }
}

function readOnboardingProgress() {
    return readCookie(ONBOARDING_COOKIE_NAME);
}

function setOnboardingProgress(value) {
    createCookieForMinutes(ONBOARDING_COOKIE_NAME, value, 720);
}

function readUserProfile() {
    try {
        const result = JSON.parse(readCookie(PROFILE_COOKIE_NAME));
        return result;
    }
    catch(e) {
        return undefined;
    }
}

function readUserScopes() {
    try {
        const result = JSON.parse(readCookie(USER_SCOPES));
        return result;
    }
    catch(e) {
        return undefined;
    }
}

/////////////////////////////////////////////
//  IP ADDRESS
/////////////////////////////////////////////
function getIpAddress() {
    return '10.10.5.31';
}

function setIpAddress(ip) {}
/////////////////////////////////////////////
// IP ADDRESS
/////////////////////////////////////////////


/////////////////////////////////////////////
// LOCAL STORAGE UTILS
/////////////////////////////////////////////

function setItem(key, value) {
    localStorage.setItem(key, value);
}

function getItem(key) {
    return localStorage.getItem(key);
}

function removeItem(key) {
    localStorage.removeItem(key);
}


function setupHeights() {
    let $responsiveGridBody = $('.body.responsivegrid');
    let isResponsiveGridPageLayout = $responsiveGridBody.length > 0;

    let contentClassName = isResponsiveGridPageLayout === true ? 'body responsivegrid' : 'parbase';
    let headerSelector = isResponsiveGridPageLayout === true ? '.globalHeader' : '.header-bucket';
    let footerSelector = isResponsiveGridPageLayout === true ? '.globalFooter' : '.footer-bucket';

    let $toStretch = $('.stretch-to-full-height');
    if ($toStretch.length === 0) {
        return;
    }
    if ($toStretch.length > 1) {
        console.error('Only one element can be marked with "stretch-to-full-height" class.');
        return;
    }

    $toStretch.css('height', 'initial');
    let originalHeight = $toStretch[0].clientHeight;
    let viewportHeight = document.documentElement.clientHeight;

    let $parents = [];
    let $i = $toStretch;
    let $firstParbase;
    while (!$i.is(document)) {
        if (!$firstParbase && $i.hasClass(contentClassName)) {
            $firstParbase = $i;
            $i = $i.parent();
            continue;
        }
        $parents.push($i);
        $i = $i.parent();
    }
    let occupiedHeight = 0;

    // if ($firstParbase) {
    //     var $siblings = $firstParbase.siblings('.iparys_inherited,.iparsys,.parsys');
    //     $siblings.each(function(index, sibling) {
    //         occupiedHeight += sibling.clientHeight;
    //     })
    // }

    try {
        occupiedHeight += $(headerSelector).height();
        occupiedHeight += $(footerSelector).height();
    } catch (e) {

    }

    // let's set the content to takeup the whole height
    if (viewportHeight - occupiedHeight >= originalHeight) {
        $parents.forEach($elem => {
            $elem.css('height', '100%');
        });
        if ($firstParbase) {
            $firstParbase.css('height', 'calc(100% - ' + occupiedHeight + 'px)');
        }
    } else {
        // even if it should be streched, we have to apply scrolling

        $parents.forEach($elem => {
            $elem.css('height', 'initial');
        });
        if ($firstParbase) {
            $firstParbase.css('height', 'initial');
        }
    }

}

/////////////////////////////////////////////
// SORTING UTILS
/////////////////////////////////////////////

function sortAsDates(values, field, direction, fieldValueResolver) {
    return _.orderBy(values, [c => {
        let fieldValue = fieldValueResolver && typeof fieldValueResolver === 'function' ? fieldValueResolver(c, field) : field;
        return new Date(c[fieldValue] || 0)
    }], direction);
}

function sortAsTimes(values, field, direction, fieldValueResolver) {
    return _.orderBy(values, [c => {
        let fieldValue = fieldValueResolver && typeof fieldValueResolver === 'function' ? fieldValueResolver(c, field) : field;
        return new Date(c[fieldValue] ? ('1970-01-01T' + c[fieldValue]) : '')
    }], direction);
}

function sortAsStrings(values, field, direction, fieldValueResolver) {
    return _.orderBy(values, [c => {
        let fieldValue = fieldValueResolver && typeof fieldValueResolver === 'function' ? fieldValueResolver(c, field) : field;
        return ('' + c[fieldValue] || '').toLowerCase();
    }], direction);
}

function sortAsNumbers(values, field, direction, fieldValueResolver) {
    return _.orderBy(values, [c => {
        let fieldValue = fieldValueResolver && typeof fieldValueResolver === 'function' ? fieldValueResolver(c, field) : field;
        return Number(c[fieldValue] || '')
    }], direction);
}

function sortAsEnvironments(values, field, direction, fieldValueResolver) {
    const environmentValues = [
        'Production',
        'Production and Development',
        'Development',
        'Test',
        'Other'
    ];
    return _.orderBy(values, [c => {
        let fieldValue = fieldValueResolver && typeof fieldValueResolver === 'function' ? fieldValueResolver(c, field) : field;
        return environmentValues.indexOf(c[fieldValue] || '');
    }], direction);
}

function sortAsImpact(values, field, direction, fieldValueResolver) {
    const perfomanceImpactValue = [
        'No',
        'Minor',
        'Severe'
    ];

    return _.orderBy(values, [c => {
        let fieldValue = fieldValueResolver && typeof fieldValueResolver === 'function' ? fieldValueResolver(c, field) : field;
        return perfomanceImpactValue.indexOf(c[fieldValue] || '');
    }], direction);
}


function sortAsVersions(values, field, direction, fieldValueResolver) {
    return _.chain(values)
        .orderBy(c1 => {
            let fieldValue = fieldValueResolver && typeof fieldValueResolver === 'function' ? fieldValueResolver(c1, field) : field;
            if (c1[fieldValue]) {
                let valueAfterSecondDots = c1[fieldValue].split('.').slice(2).join('.').replace(/[^\d.]/g, '');
                let convertedNumberValue = Number(valueAfterSecondDots);
                return isNaN(convertedNumberValue) ? valueAfterSecondDots : convertedNumberValue;
            }

            return '';
        }, direction)
        .orderBy(c2 => {
            let fieldValue = fieldValueResolver && typeof fieldValueResolver === 'function' ? fieldValueResolver(c2, field) : field;
            if (c2[fieldValue]) {
                let valueBetweenFirstAndSecondDots = c2[fieldValue].split('.').slice(1, 2).join('').replace(/[^\d.]/g, '');
                let convertedNumberValue = Number(valueBetweenFirstAndSecondDots);
                return isNaN(convertedNumberValue) ? valueBetweenFirstAndSecondDots : convertedNumberValue;
            }

            return '';
        }, direction)
        .orderBy(c3 => {
            let fieldValue = fieldValueResolver && typeof fieldValueResolver === 'function' ? fieldValueResolver(c3, field) : field;
            if (c3[fieldValue]) {
                let valueBeforeFirstDot = c3[fieldValue].split('.')[0].replace(/[^\d.]/g, '');
                let convertedNumberValue = Number(valueBeforeFirstDot);
                return isNaN(convertedNumberValue) ? valueBeforeFirstDot : convertedNumberValue;
            }

            return '';
        }, direction)
        .orderBy(c => {
            let fieldValue = fieldValueResolver && typeof fieldValueResolver === 'function' ? fieldValueResolver(c, field) : field;
            return (c[fieldValue] || '').indexOf('.') > -1;
        }, 'desc')
        .value();
}

function sortAsBooleans(values, field, direction) {
    return _.orderBy(values, [field], [direction]);
}

function sortAsValidationSeverities(values, field, direction, fieldValueResolver) {
    const severityValues = [
        'CRITICAL',
        'ERROR',
        'WARN',
        'CURIOSITY',
        'INFO'
    ];
    return _.orderBy(values, [c => {
        let fieldValue = fieldValueResolver && typeof fieldValueResolver === 'function' ? fieldValueResolver(c, field) : field;
        return severityValues.indexOf(c[fieldValue] || '');
    }], direction);
}

function sortAsAccountList(values, field, direction, valueResolver) {
    return _.orderBy(values, ['isNative', 'name'], ['desc', 'asc']);
}

function filterByMajorVersion(items, field, versionPredicate) {
    return _.filter(items, item => {
        const filterValue = item[field];
        const majorVersionFieldValue = filterValue.split('.')[0].replace(/[^\d.]/g, '');
        const majorVersion = majorVersionFieldValue.length > 0 ? Number(majorVersionFieldValue) : Number.NaN;
        return versionPredicate(majorVersion);
    });
}

function majorVersionBiggerThanPredicate(minimumMajorVersion) {
    return (majorVersion) => {
        return majorVersion >= minimumMajorVersion;
    };
}

function majorVersionMatchPredicate(targetMajorVersion) {
    return (majorVersion) => {
        return majorVersion == targetMajorVersion;
    };
}

function majorVersionSmallerThanPredicate(maximumMajorVersion) {
    return (majorVersion) => {
        return majorVersion <= maximumMajorVersion;
    };
}

function sortAsNumericString(values, field, direction, fieldValueResolver){
    return _.orderBy(values, (obj) => parseInt(obj[field], 10), [direction]);
}

/////////////////////////////////////////////
// FORMATTING UTILS
/////////////////////////////////////////////

function formatNumberWithCommas(numberToFormatParam, minimumFractionDigits = 0, maximumFractionDigits = 3) {
    const numberToFormat = Number(numberToFormatParam);

    if (isNaN(numberToFormat)) {
        return numberToFormatParam;
    }

    return numberToFormat.toLocaleString('en', {
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits
    });
}

function formatCurrency(value, currency, removeDecimals, minimumFractionDigits = 2, maximumFractionDigits = 2) {
    if (value == '-') {
        return '-';
    }

    if (isNaN(Number(value))) {
        return value;
    }

    try {
        // toLocaleString can't understand CC as a currency
        const clouderaCreditsCurrency = 'CC';
        const safeCurrency = currency === clouderaCreditsCurrency ? 'USD' : currency;
        const locale = CurrencyLocales[safeCurrency] || 'en';

        if (currency === clouderaCreditsCurrency) {
            const currencyFractionDigits = new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: safeCurrency,
                minimumFractionDigits,
                maximumFractionDigits
            }).resolvedOptions();

            return Number(value).toLocaleString(locale, {
                maximumFractionDigits: currencyFractionDigits.maximumFractionDigits,
                minimumFractionDigits: currencyFractionDigits.minimumFractionDigits 
            });
        }

        return Number(value).toLocaleString(locale, {
            minimumFractionDigits: (removeDecimals === true || currency === 'JPY' ? 0 : minimumFractionDigits),
            maximumFractionDigits: (removeDecimals === true || currency === 'JPY' ? 0 : maximumFractionDigits),
            style: 'currency',
            currencyDisplay: 'narrowSymbol',
            currency: safeCurrency
        });
    } catch (e) {
        return '-';
    }
}


/////////////////////////////////////////////
// FILE EXPORT UTILS
/////////////////////////////////////////////

function convertToCsv(dataToConvert) {
    return Papa.unparse(dataToConvert);
}

function downloadData(dataToDownload, filename) {
    var blob = new Blob(["\ufeff", dataToDownload]);

    if (window.navigator.msSaveOrOpenBlob) // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
        window.navigator.msSaveBlob(blob, filename);
    else {
        var a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(blob, { type: "text/plain" });
        a.download = filename;
        document.body.appendChild(a);
        a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
        document.body.removeChild(a);
    }
}


/////////////////////////////////////////////
// USER UTILS
/////////////////////////////////////////////

function getActorInfo() {
    return new Promise((res, rej) => {

        /* develblock:start */
        res(ACTOR_MOCK);
        return;
        /* develblock:end */

        let hasActor = hasDeepValue(Cloudera, 'Analytics.RequestDescriptor.actor');
        if (hasActor) {
            let actor = getDeepValue(Cloudera, 'Analytics.RequestDescriptor.actor');
            res(actor)
            return;
        } else {
            setTimeout(async() => {
                const aInfo = await getActorInfo();
                res(aInfo);
                return;
            }, 50)
        }
    });
}

function getActorProperty(prop, actorInfoProvided) {
    let actorInfo = actorInfoProvided;

    if (!actorInfoProvided) {
        /* develblock:start */
        setDeepValue(window.Cloudera, 'Analytics.RequestDescriptor.actor', ACTOR_MOCK);
        /* develblock:end */

        if (hasDeepValue(Cloudera, 'Analytics.RequestDescriptor.actor')) {
            actorInfo = getDeepValue(Cloudera, 'Analytics.RequestDescriptor.actor');
        }
    }

    return getDeepValue(actorInfo, prop) || "";
}

function isAuthenticated(actorInfoProvided) {
    let oktaId = getActorProperty('oktaId', actorInfoProvided);

    return oktaId.length > 0;
}

function getContactId(actorInfoProvided) {
    return getActorProperty('contactId', actorInfoProvided);
}

function getAccountId(actorInfoProvided) {
    return getActorProperty('accountId', actorInfoProvided);
}

function getRoles(actorInfoProvided) {
    let portalRolesValue = getActorProperty('portalRoles', actorInfoProvided);
    return portalRolesValue.toLowerCase().split(',');
}

function hasRole(rolesToCheck, actorInfoProvided) {
    let portalRoles = getRoles(actorInfoProvided);
    let rolesToCheckArray = typeof rolesToCheck === 'string' ? [rolesToCheck] : rolesToCheck;
    let commonRoles = _.intersection(portalRoles, rolesToCheckArray);

    return commonRoles.length > 0;
}

function hasAllRoles(rolesToCheck, actorInfoProvided) {
    let portalRoles = getRoles(actorInfoProvided);
    return _.every(rolesToCheck, (r) => { return portalRoles.indexOf(r) > -1; });
}

/////////////////////////////////////////////
// FEATURE UTILS
/////////////////////////////////////////////

let APP_FEATURES = {};

let APP_FEATURE_CONSTS = {
    useCoveoSearch: 'useCoveoSearch'
};

function setFeature(featureFlagKey, featureFlagValue) {
    if (APP_FEATURES) {
        APP_FEATURES[featureFlagKey] = featureFlagValue;
    }
}

function getFeature(featureFlagKey) {
    if (APP_FEATURES) {
        if (APP_FEATURES.hasOwnProperty(featureFlagKey)) {
            return APP_FEATURES[featureFlagKey];
        }
    }

    return false;
}

function decodeJwtToken(token) {
    try {
        return jwtDecode(token);
    } catch (error) {
        console.log(error)
    }
}


/////////////////////////////////////////////
// EXPORTS
/////////////////////////////////////////////
export default {
    jwt: {
        decode: decodeJwtToken
    },
    formatting: {
        numberWithCommas: formatNumberWithCommas,
        currency: formatCurrency,
    },
    sorting: {
        date: sortAsDates,
        time: sortAsTimes,
        string: sortAsStrings,
        number: sortAsNumbers,
        boolean: sortAsBooleans,
        environment: sortAsEnvironments,
        version: sortAsVersions,
        performanceImpact: sortAsImpact,
        account: sortAsAccountList,
        validationSeverity: sortAsValidationSeverities,
        numericString: sortAsNumericString
    },
    filtering: {
        majorVersion: filterByMajorVersion,
        predicates: {
            minimumMajorVersion: majorVersionBiggerThanPredicate,
            exactMajorVersion: majorVersionMatchPredicate,
            maximumMajorVersion: majorVersionSmallerThanPredicate
        }
    },
    url: {
        getPart: (id) => { return getUrlSearchPart(id); },
        removeSearchArgs: removeSearchArgs,
        redirect: redirectThroughRedirectPage,
        redirectTo: redirectToTarget,
        cancelRedirect: cancelRedirect,
        redirectToOnboarding: redirectToOnboarding
    },
    breakpoints: {
        getCurrentBreakpoint: getCurrentBreakpoint
    },
    object: {
        hasDeepValue: hasDeepValue,
        getDeepValue: getDeepValue,
        setDeepValue: setDeepValue,
        traverseHierarchy: traverseHierarchy,
        traverseUpHierarchy: traverseUpHierarchy,
        flattenHierarchyBy: flattenHierarchyBy
    },
    requests: {
        fetchSecurityQuestions: fetchSecurityQuestions
    },
    dom: {
        removeHeaderAndFooterTrails: removeHeaderAndFooterTrails,
        registerReadyCallback: registerDomReadyCallback,
        setupHeights: setupHeights,
        scrollToTop: scrollToTop,
        checkStoreActionsForPageLoadedState: checkStoreActionsForPageLoadedState,
        setPageLoadedState: setPageLoadedState,
        createPageLoadedCheckerAppExtension: createPageLoadedCheckerAppExtension
    },
    cookie: {
        createForDays: createCookieForDays,
        createForMinutes: createCookieForMinutes,
        createForSession: createCookieForSession,
        read: readCookie,
        remove: removeCookie,
        removeSsoRelatedCookies: removeSsoRelatedCookies,
        setAccessToken,
        readAccessToken,
        setRefreshToken,
        readRefreshToken,
        setUserProfile,
        setUserScopes,
        readUserProfile,
        readUserScopes,
        removeLoginRelatedCookies,
        readOnboardingProgress,
        setOnboardingProgress
    },
    ip: {
        get: getIpAddress,
        set: setIpAddress
    },
    storage: {
        setItem: setItem,
        getItem: getItem,
        removeItem: removeItem
    },
    data: {
        convertToCsv: convertToCsv,
        download: downloadData
    },
    user: {
        actor: getActorInfo,
        getActorProperty: getActorProperty,
        getRoles: getRoles,
        hasRole: hasRole,
        hasAllRoles: hasAllRoles,
        isAuthenticated: isAuthenticated,
        getContactId: getContactId,
        getAccountId: getAccountId
    },
    feature: {
        set: setFeature,
        get: getFeature,
        consts: APP_FEATURE_CONSTS
    }
}