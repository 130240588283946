import Utilities from "./utilities";
import { SAML_REQUEST_COOKIE_NAME } from "./utilities";
import _ from "lodash"

const SSO_URL_PARAM_NAME = "SSOurl";
const AEM_CUG_PARAM_NAME = "resource";
const SSO_COOKIE_NAME = "sso-url";
const LOGGED_OUT = "logged-out";

function initializeCookieFromUrl(referrerHostnameWhitelist) {
    const cookieFromUrl = _processUrlParams(window.location.search);
    if (cookieFromUrl) {
        toCookie(cookieFromUrl);
        return;
    }

    if (document.cookie.includes(SAML_REQUEST_COOKIE_NAME) || document.cookie.includes(LOGGED_OUT)) {
        return;
    }

    const cookieFromReferrer = _processReferrer(document.referrer, referrerHostnameWhitelist);
    if (cookieFromReferrer) {
        toCookie(cookieFromReferrer);
    }

    function toCookie(c) {
        Utilities.cookie.createForDays(c.name, c.value, 1);
    }
}

export function _processUrlParams(location) {
    const urlParams = new URLSearchParams(location);
    if (urlParams.has(SSO_URL_PARAM_NAME)) {
        return { name: SSO_COOKIE_NAME, value: urlParams.get(SSO_URL_PARAM_NAME) };
    }

    if (urlParams.has(AEM_CUG_PARAM_NAME)) {
        return { name: SAML_REQUEST_COOKIE_NAME, value: urlParams.get(AEM_CUG_PARAM_NAME) };
    }

    return null;
}

export function _processReferrer(referrer, referrerHostnameWhitelist) {

    if (referrer && _.isArray(referrerHostnameWhitelist)) {
        let hostname;
        try {
            hostname = new URL(referrer).hostname;
        } catch (e) {
            // IE fix
            const tempA = document.createElement('a');
            tempA.href = referrer;
            hostname = tempA.hostname;
        }

        if (referrerHostnameWhitelist.filter(w => hostname.endsWith(w)).length > 0) {
            return {
                name: SAML_REQUEST_COOKIE_NAME,
                value: referrer
            };
        }
    }
    return null;
}

/////////////////////////////////////////////
// EXPORTS
/////////////////////////////////////////////
export default {
    cookie: {
        initializeFromUrl: initializeCookieFromUrl
    }
};