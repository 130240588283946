import ADMIN_USER_HISTORY_FIELD_MAPPINGS from '../../config/adminUserHistory.fields.mapping.json';

import Utilities from '../utilities';
import moment from "moment-timezone";
import _ from "lodash";

export class AdminUserHistory {

    constructor(userHistoryData) {
        const inputUserHistoryItemData = userHistoryData || {};

        Object.keys(ADMIN_USER_HISTORY_FIELD_MAPPINGS).forEach(fieldName => {
            if (inputUserHistoryItemData.hasOwnProperty(ADMIN_USER_HISTORY_FIELD_MAPPINGS[fieldName])) {
                const val = inputUserHistoryItemData[ADMIN_USER_HISTORY_FIELD_MAPPINGS[fieldName]];

                if (val === 'null' || val === null || val === "") {
                    this[fieldName] = '-';
                } else {
                    this[fieldName] = val;
                }
            }
        });

        this.targetUserName = this.targetUserFirstName + ' ' + this.targetUserLastName;
        this.accountName = this.targetAccountName;
        this.FullName = this.FirstName + ' ' + this.LastName;
        this.IsClouderan = this.Email.endsWith('@cloudera.com');
        this.DateFormatted = moment(this.Date).tz(moment.tz.guess()).format('YYYY-MM-DD h:mm A');
    }
}