function validator(val) {
  return new Promise(res => {
    res(validateDate(val));
  });
}


function validateDate(val) {
  if (val === null || typeof val === "number" || typeof val === "boolean" || typeof val === "undefined" || typeof val === "function") {
   return {
      result: false,
      state: { details: {} }
    }  
  }

  const date = new Date(val);
  const result = "Invalid Date" === date.toString() ? false : Boolean(date);
  
  return  {
    result: result,
    state: { details: {} }
  }
}

export default {
  validator: validator,
  validationErrorMessage: "Input cannot be parsed to date."
};
