import Api from '../../api/api'

function validator(val) {
    return new Promise(async(res) => {
        const emailId = encodeURIComponent(val.trim());

        const validity = true;

        const state = {
            details: {
                isUserMigrated: false,
                unsuccessfulAuth: false
            }
        };

        try {
            const response = await Api.callEndpoint("SKYNET_CHECK_MIGRATED_USER", { urlParams: { emailId } });

            let isUserMigrated = response.isMigrated;
            let unsuccessfulAuth = response.unsuccessfulAuth;

            state.details.isUserMigrated = isUserMigrated;
            state.details.unsuccessfulAuth = unsuccessfulAuth

            if (isUserMigrated === true) {
                state.details.admonition = {
                    type: 'dcxa-neutral',
                    visible: isUserMigrated,
                    message: 'This email is associated with a migrated Hortonworks community account and needs to be activated. <br /><a class=\"suds-link inline-link\" href=\"/content/support/sso/en-us/activate.html?email=<<<EMAIL_FOR_MIGRATION>>>\">Please activate your account here.</a>'
                }
            }

        } catch (e) {
            // Handle exception here
        }

        res({
            result: validity,
            state: state
        });
    });
}



export default {
    validator: validator,
    validationErrorMessage: '',
    validationErrorHint: ''
}