function validator(val, $country) {
    return new Promise((res) => {
        setTimeout(function() {
            if ($country.val() == 'USA') {
                res({
                    result: typeof val !== 'undefined' && val != null && val.length > 0,
                    state: { details: {} }
                });
            } else {
                res({
                    result: true,
                    state: { details: {} }
                });
            }
        });
    });
}

export default {
    validator: validator,
    validationErrorMessage: 'Please fill this field.',
    validationErrorHint: 'This field can not be blank.'
}