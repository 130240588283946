const NOT_CLOUDERIAN_EMAIL_VALIDATION_MESSAGES = [
    'Clouderans must log in using your corporate credentials.',
    'Clouderans cannot reset passwords through this page and must log in using your corporate credentials.'
];

function validator(val, validationMessageIndex = 0) {
    return new Promise((res) => {
        const state = {
            details: {}
        }
        const result = !val.endsWith('@cloudera.com');

        state.details.validationMessage = NOT_CLOUDERIAN_EMAIL_VALIDATION_MESSAGES[validationMessageIndex] || NOT_CLOUDERIAN_EMAIL_VALIDATION_MESSAGES[0];

        res({
            result: result,
            state: state
        });
    });
}

export default {
    validator: validator,
    validationErrorMessage: '',
    messageParser: function(msg, state) {
        return state.details.validationMessage;
    }
}