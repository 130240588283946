import EventModelBase from "../eventModelBase";

export default class CaseSubmissionError extends EventModelBase {

    constructor(application, feature, clientSideErrorCode, skynetErrorCode ) {
        super('caseSubmissionError');

        this.page = {
            ...this.page,
        }

        this.attributes = {
            ...this.attributes,
            application: application,
            feature: feature,

            skynetErrorCode   : skynetErrorCode,
            clientSideErrorCode : clientSideErrorCode 
        };
    }

    
}