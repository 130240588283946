import _ from 'lodash'
import Validators from './validators/validators'


const Rulesets = {
    EmailSet: [
        { rule: 'minlength', args: [5] },
        { rule: 'maxlength', args: [100] },
        { rule: 'email' }
    ],
    NameSet: [
        { rule: 'minlength', args: [1] },
        { rule: 'maxlength', args: [30] },
        { rule: 'onlyNameSymbol' },
        { rule: 'noNumber' }
    ],
    NicknameSet: [
        { rule: 'minlength', args: [2] },
        { rule: 'maxlength', args: [15] },
        { rule: 'checkNicknameRegexp' }
    ],
    CitySet: [
        { rule: 'minlength', args: [1] },
        { rule: 'maxlength', args: [60] },
        { rule: 'noNumber' },
        { rule: 'onlyCitySymbols' }
    ],
    PhoneSet: [
        { rule: 'minlength', args: [1] },
        { rule: 'maxlength', args: [30] },
        { rule: 'onlyPhoneSymbols' }
    ]
}

function replacePlaceholders(text, args) {
    text = text || '';
    args = args || [];

    for (const argId in args) {
        const currPattern = '{{' + argId + '}}';
        while (text.indexOf(currPattern) > -1) {
            text = text.replace(currPattern, args[argId]);
        }
    }
    return text;
}

function defaultParser(text, state) {
    return text || '';
}

async function executeRules(value, rules, carryState, startWithRule) {
    const i = startWithRule | 0;
    const state = carryState || {};

    // if no more rule, return
    if (i === rules.length) {
        return { result: true, state: state };
    }

    const ruleObj = rules[i];
    const ruleChecker = Validators[ruleObj.rule + 'Validator'].validator || Validators[ruleObj.rule].validator || Validators.defaultValidator.validator;

    // run the rule
    const ruleResult = await ruleChecker.apply(undefined, [].concat(value, ruleObj.args));
    state[ruleObj.rule] = ruleResult;

    // if the rule succeeds, continue with the next rule
    if (ruleResult.result) {
        if (typeof ruleObj.onSuccessValidation === 'function') {
            let shouldStopValidationChain = ruleObj.onSuccessValidation(ruleResult);
            if (shouldStopValidationChain) {
                return { result: shouldStopValidationChain, state: state };
            }
        }

        return await executeRules(value, rules, state, i + 1);
    }

    // if the rule fails, return 
    return { result: false, state: state };
}

export default {

    Rulesets: Rulesets,

    validate: async(value, config) => {
        config = config || {};
        const validationConfig = config.validation || {};
        const rules = validationConfig.rules || [];

        if (!config.disabled) {
            const validationResult = await executeRules(value, rules, 0);

            let validationErrorMessage = '';
            let validationErrorHint = '';

            if (!validationResult.result) {
                const firstFailedRuleObj = _.find(rules, ruleObj => !(validationResult.state[ruleObj.rule].result));
                const ruleValidator = Validators[firstFailedRuleObj.rule + 'Validator'];

                const ruleArgs = firstFailedRuleObj.args;

                const messageParser = ruleValidator.messageParser || defaultParser;
                const hintParser = ruleValidator.hintParser || defaultParser;
                const ruleState = validationResult.state[firstFailedRuleObj.rule].state;

                validationErrorMessage = replacePlaceholders(messageParser(ruleValidator.validationErrorMessage, ruleState), ruleArgs);
                validationErrorHint = replacePlaceholders(hintParser(ruleValidator.validationErrorHint, ruleState), ruleArgs);
            }



            return {
                result: validationResult.result,
                state: validationResult.state,
                value: value,
                message: validationErrorMessage,
                hint: validationErrorHint
            }
        } else {
            return {
                result: true,
                state: {},
                value: value,
                message: '',
                hint: ''
            }
        }

    }


}