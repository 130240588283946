function validator(val, $anotherInput) {
    return new Promise((res) => {
        $anotherInput = $anotherInput || '';
        const anotherVal = (typeof $anotherInput === 'string') ? $anotherInput : $anotherInput.val();

        res({
            result: val !== anotherVal,
            state: { details: {} }
        });
    });
}

export default {
    validator: validator,
    validationErrorMessage: 'Your old and new password can not be identical.'
}