import EventModelBase from "../eventModelBase";

export default class NewCaseInteraction extends EventModelBase {

    constructor(application, feature, action, actionValue, summary) {
        super('newCaseInteraction');

        this.page = {
            ...this.page,
        }

        this.attributes = {
            ...this.attributes,
            application: application,
            feature: feature,

            action  : action,
            actionValue : actionValue,
            summary: summary
        };
    }


}