// simple email check
const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// RFC 5322 + no leading/trailing/consecutive dots + top level domain can contain only letters and has to be 2 to 6 chars long
// http://howtodoinjava.com/regex/java-regex-validate-email-address/
const EMAIL_RFC_5322_EXTENDED = /^[\w!#$%&’'*+\/=?`{|}~^-]+(?:\.[\w!#$%&’'*+\/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;


function validator(val) {

    return new Promise((res) => {
        res({
            result: EMAIL_RFC_5322_EXTENDED.test(val),
            state: { details: {} }
        });
    });
}

export default {
    validator: validator,
    validationErrorMessage: 'Please enter a valid email address.'
}