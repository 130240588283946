import EventModelBase from "../eventModelBase";

export default class JourneyAdvisorAssetLoadingErrorModel extends EventModelBase {

    constructor(assetId, skynetError, clientError) {
        super('journeyAdvisorAssetLoadingError');

        this.page = {
            ...this.page,

        }

        this.attributes = {
            ...this.attributes,

            assetId: assetId,

            skynetErrorCode: skynetError,
            clientSideErrorCode: clientError
        };
    }




}